import grey from "@material-ui/core/colors/grey";
import {createMuiTheme} from "@material-ui/core/styles";
import React, {useEffect, useState} from "react";
import {request} from "../index";
import { ThemeProvider } from '@material-ui/core/styles';
const DEFAULT = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#69c0d5',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            main: grey[200],
            // dark: will be calculated from palette.secondary.main,
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
    },
});

let _theme = undefined;
let currentGym = "test";
let loading = false;

let callbackList = [];
function load(gym, callback){
    callbackList.push(callback);
    if(!loading){
        loading=true;
        console.log("Fetching theme")
        request.get("/"+gym+"/theme").then((res)=>{
            currentGym = gym;
            try {
                _theme = createMuiTheme(res.result);
            }catch(e){
                _theme = DEFAULT;
            }
            while(callbackList.length >0){
                callbackList.pop()(_theme);
            }
            console.log("done")
        }).finally(()=>loading = false)

    }
}


export default function useGymTheme(gym){


    const [theme, setTheme] = useState(DEFAULT);

    useEffect(()=>{

        //console.log(theme, _theme, currentGym, gym)

        if(!_theme || currentGym !== gym){
            load(gym, setTheme)
        }else if(_theme){
            setTheme(_theme);
        }

    },[gym])


    return theme;
}


export function GymTheme({gym, children}){

    const theme = useGymTheme(gym);



    return(
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    )
}
