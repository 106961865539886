import React, {useEffect, useState} from 'react';
import {useLoggedInRequest} from "../common/components/useRequest";
import Select from "@material-ui/core/Select";
import {State} from "../common/components/Response";
import MenuItem from "@material-ui/core/MenuItem";
import {SelectDialog} from "../common/utils/useConfirmDialog";
import {useTranslation} from "react-i18next";


export default function SelectAccreditation({gym, onSelect,
                                                open,
                                                onCancel, onConfirm,
                                                }){

    const [t] = useTranslation("forms")
    const [accreditationList, setAccreditationList] =useState([]);

    useLoggedInRequest(gym)
        .call((request)=>{
            request.get("/accreditations").then((res) => {

                if(res.result && res.state === State.SUCCESS) {
                    setAccreditationList(res.result);
                }else{
                    setAccreditationList([]);
                }
            })
        })
    return(
        <SelectDialog
            open={open}
            onCancel={onCancel}
            onConfirm={onConfirm}
            text={t('selectAnAccreditation')}
            options={accreditationList}
            label={value=>value.category +": "+value.title}
        />
    )
}