import {useTranslation} from "react-i18next";
import {i18next} from "..";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";


const useStyles = makeStyles(()=>({
    image:{
        width:'70%',
        borderRadius:'2em',
        border: '2px solid'
    },app:{
        width:'100%',
        textAlign:'center'
    },
}))
export default function NotFound(){
    const [t] = useTranslation('common', {i18n:i18next});
    const classes  = useStyles();
    return (
        <div className={classes.app}>
            <img className={classes.image} src={"/NotFound.png"} alt={"Not Found"}/>
            <h1>{t("notFound")}</h1>
        </div>
    )
}