import CryptoJS from "crypto-js";



export function deepEqual(ob1, ob2){

    function check(_ob1, _ob2){
        if(_ob1 && _ob2) {
            let is = true;

            for (let x in _ob1) {
                if (_ob1[x] !== _ob2[x]) {
                    is = false;
                }
            }
            return is;
        }else{
            return false;
        }
    }

    return check(ob1,ob2) && check(ob2,ob1);
}

export function getTime(seconds){
  const json = {};

  json.hours = Math.floor(seconds/3600);
  seconds = seconds % 3600;
  json.minutes = Math.floor(seconds/60);
  seconds = seconds % 60;
  json.seconds = Math.floor(seconds);
  json.milliseconds = seconds - json.seconds;

  json.string =
    (json.hours>0? json.hours + ":":"")+
    (json.minutes <10?"0":"")+json.minutes+":"+
    (json.seconds<10?"0":"")+json.seconds;

  return json;
}

export function fromBase64(base64){
    let words = CryptoJS.enc.Base64.parse(base64);
    return CryptoJS.enc.Utf8.stringify(words);
}

