import {Dialog} from "@material-ui/core";
import React, {forwardRef, useState} from "react";
import Slide from "@material-ui/core/Slide";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";


const Transition = forwardRef((props,ref)=>{
    return <Slide direction={"up"} ref={ref} {...props}/>
})


export default function useConfirmDialog(defaultType = ConfirmDialog){
    const [state, setState] = useState(<div/>);

    async function confirm({Type = defaultType, ...props}){
        return new Promise((then)=>{
            setState(<Type
                {...props}
                open={true}
                onClose={()=>{
                    setState(<div/>)
                    then(undefined);
                }}
                onCancel={(value)=>{
                    setState(<div/>);
                    then(value? value:false);
                }}
                onConfirm={(value)=>{
                    setState(<div/>);
                    then(value? value:true);
                }}
            />)
        })
    }

    return [state, confirm]
}


export function AlertDialog({open,title, text,
                                confirmDisabled=false,
                                confirmText="OK",
                                onConfirm, onCancel,
                                children, actions

                            }){
    return(
        <Dialog open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={()=>onCancel && onCancel()}
                fullWidth
                maxWidth={"sm"}
        >

            {title &&
            <DialogTitle>{title}</DialogTitle>
            }
            <DialogContent>
                <DialogContentText>
                    {text}
                </DialogContentText>
                {children}
            </DialogContent>

            <DialogActions>
                {actions}
                <Button onClick={()=>onConfirm && onConfirm()}
                        disabled={confirmDisabled}>
                    {confirmText}
                </Button>

            </DialogActions>
        </Dialog>
    )

}

export function ConfirmDialog({open,title, text,
                                  confirmDisabled=false,
                                  confirmText="OK",
                                  cancelText="Cancel",
                                  onCancel,
                                  onConfirm, children
                              }){

    return(
        <AlertDialog
            open={open}
            title={title}
            text={text}
            confirmText={confirmText}
            onConfirm={onConfirm}
            confirmDisabled={confirmDisabled}
            children={children}
            onCancel={onCancel}
            actions={[
                <Button key={"cancel"} onClick={()=>onCancel && onCancel()}>
                    {cancelText}
                </Button>
            ]}
        />
    )
}

export function ConfirmTextDialog({open,title, text,
                                  confirmText="OK",
                                  cancelText="Cancel",
                                  onCancel, onConfirm, textToEnter, label
                              }){
    const [textEqual, setTextEqual] =useState(false);


    return(
        <ConfirmDialog
            open={open}
            title={title}
            text={text}
            confirmText={confirmText}
            cancelText={cancelText}
            onCancel={()=>onCancel && onCancel(false)}
            onConfirm={()=>onConfirm && onConfirm(true)}
            confirmDisabled={!textEqual}
            >
            <TextField fullWidth
                       label={label?label:"Enter '"+textToEnter+"' to confirm"}
                       onChange={(e)=>setTextEqual(textToEnter == e.target.value)}
            />
        </ConfirmDialog>
    )
}

export function InputDialog({open,title, text,
                                confirmText="OK",
                                cancelText="Cancel",
                                onCancel, onConfirm,label, defaultValue
}){

    const [state, setState] = useState(defaultValue)

    return(<ConfirmDialog
        open={open}
        title={title}
        text={text}
        confirmText={confirmText}
        cancelText={cancelText}
        onCancel={()=>onCancel && onCancel()}
        onConfirm={()=>onConfirm && onConfirm(state)}
        confirmDisabled={state === undefined || state === ""}
    >
        <TextField fullWidth
                   label={label}
                   value={state}
                   autoFocus
                   onChange={(e)=>setState(e.target.value)}
        />
    </ConfirmDialog>)

}


export function SelectDialog({open,title, text,
                                 confirmText="OK",
                                 cancelText="Cancel",
                                 onCancel, onConfirm,label='label', defaultValue ="none", options = []}){

    const [state, setState] = useState(defaultValue)

    return(<ConfirmDialog
        open={open}
        title={title}
        text={text}
        confirmText={confirmText}
        cancelText={cancelText}
        onCancel={()=>onCancel && onCancel()}
        onConfirm={()=>onConfirm && onConfirm(options[state])}
        confirmDisabled={state === undefined || state === ""}
    >
        <Select value={state} onChange={e=>setState(e.target.value)} fullWidth>
            <MenuItem value={"none"} disabled>- Select -</MenuItem>
            {
                options.map((ob1, i)=><MenuItem key={ob1.ID+"_"+i} value={i}>
                    {typeof label === 'function'? label(ob1): ob1[label]}
                </MenuItem>)
            }
        </Select>
    </ConfirmDialog>)

}
