import React, {useEffect, useState} from 'react';
import {Collapse, Paper} from "@material-ui/core";
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor:"none",
    background:"none"
  },
  showMore:{
    width:"100%",
    "& hr":{
      background: theme.palette.textSecondary,
      margin: "0.2em 0 0"
    }
  },
  title:{
    width:"100%",
    textAlign:"left",
    margin:0,
    color:theme.palette.textSecondary,
    "&:hover":{
      cursor:"pointer",
      backgroundColor:"rgba(0,0,0,0.11)"
    }
  }
}));

export default function ShowMore({children, title, collapsedHeight, initialState =false, onExpand, onChange}){
  const classes = useStyles();
  const [active, setActive] = useState(initialState);

  useEffect(()=>{
    setActive(initialState)
  },[initialState])


  function toggle(){
    if(!active && onExpand){
      onExpand();
    }
    setActive((prev)=>{
      if(onChange){
        onChange(!prev)
      }
      return !prev
    })
  }

  return(
    <div className={classes.showMore}>
      <Typography color={"textSecondary"} className={classes.title + " clickable"} onClick={toggle}>
        {active?
          <ExpandLessRoundedIcon/>:
          <ExpandMoreRoundedIcon/>
        }
        {title? title: (active?"Show Less":"Show More")}
      </Typography>
      <Collapse in={active} collapsedHeight={collapsedHeight}>
        <Paper  className={classes.root  +" children"} elevation={0}>
          {children}
        </Paper>
      </Collapse>
    </div>
  )
}
