
export const State={
    "SUCCESS": 1,
    "FAILED": 0,
    "UNAUTHORIZED":-1,
    "CANCELLED":-2,
    "NOT_FOUND":-3,
    "FORBIDDEN":-4

};
export default class Response{

    constructor(serverResponse){
        this.state = State[serverResponse.state];
        this.message = serverResponse.message;
        this.result = serverResponse.result;
        this.code = serverResponse.errorCode? serverResponse.errorCode:"OKAY"

        if(!this.state && !this.message && !this.result && serverResponse){//TODO work on this
            this.state = State.SUCCESS;
            this.message = "Server data";
            this.result = serverResponse;
        }

    }

}
