import React, {Fragment, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useFragmentIdentifier} from "../utils/useQuery";

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 2 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const styles =makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: "none",
    },
    indicator:{
        top:"0"
    },
    tab:{
        borderTop: `1px solid ${theme.palette.divider}`
    },
    tabContainer:{}
}));


export default function TabComponent({icons = [], children =[], labels=[], tabs=[], onChange, initialState, identifiers}){
    const [value, setValue] = useState(identifiers?-1:0);
    const classes = styles();
    const [identifier, setIdentifier] = useFragmentIdentifier()


    useEffect(()=>{
        if(initialState !== undefined) {
            setValue(initialState);
        }
    },[initialState])


    useEffect(()=>{
        if(identifier !== undefined && identifiers) {
            for(let i = 0; i< identifiers.length;i++){
                if(identifier === identifiers[i]){
                    setValue(i);
                    return;
                }
            }
            setValue(0);
        }
    },[identifier])

    const renderedTabs= useMemo(()=>{
      if(icons && icons.length ){
        return icons.map((icon, index)=>(
          <Tab key={index} icon={icon} label={labels && labels[index]} />
        ))
      }else if(tabs && tabs.length){
        return tabs.map((tab, index)=>(
          <Tab key={index} icon={tab.icon} label={tab.label} />
        ))
      }
    },[...icons,labels+"",tabs+""])

    function handleChange(e,value){

        setValue(value);
        if(onChange){
          onChange(value, tabs[value])
        }

        if(identifiers && identifiers[value]){
          setIdentifier(identifiers[value]);
        }
    }
    return (
        <div className={classes.root}>
            {value !== undefined && value >=0 && <Fragment>
                <Tabs value={value}
                      classes={{
                          indicator:classes.indicator,
                          root: classes.tab
                      }}
                      onChange={handleChange}
                      variant="fullWidth"
                      scrollButtons="off"
                      indicatorColor="primary"
                      textColor={"primary"}

                >
                    {renderedTabs}
                </Tabs>

                <TabContainer fullWidth>{
                    children[value]?children[value] :<div/>
                }</TabContainer>
            </Fragment>}

        </div>
    );
}

