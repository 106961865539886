
export const StructureComponent = (t)=>
`
<div data-gjs-type="structure" 
    data-gjs-editable="true"
    data-gjs-droppable="true" draggable="true"
    data-highlightable="1" class="structure">
<style>
    .structure{
    width:80%;
    padding: 0 8px;
    margin: auto;
    }
    @media(max-width: 480px){
    .structure{
        width:100%;
        margin:0;
    }
    }
</style>
    <span data-gjs-editable="true" data-gjs-type="text" draggable="true" data-highlightable="1" class="">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
    </span>
    <br/>
    <br/>
</div>
`;

export const StructureBlock =()=>({
    label: `<span>
<div class="fa fa-columns" style="font-size:2em"></div><br/><br/>
Structure
</span>`,
    attributes:{
        style:""
    },
    content: StructureComponent(),
    category: 'Basic',
    type:"structure",
    model:{
        defaults:{
            resizable:{
                "cl":1,
                "cr":1,
                tl:0,
                tc:0,
                tr:0,
                bl:0,
                br:0,
                bc:0,
                autoHeight:"true"
            }
        },
        isComponent:el=>{
            let className = el.className+" "+ (el.getAttribute? el.getAttribute("classname"):'');
            console.log(className);
            if(className && className.includes("structure")){
                return {type:"structure"};
            }
        }
    }
})