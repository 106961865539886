import React, {useEffect, useMemo, useState} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {request} from "./Requests"
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    whiteSpace:'pre-wrap',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



export function MultipleSelect({label, list=[], onChange, onCommit, initialValue = []}) {
  const classes = useStyles();
  const theme = useTheme();
  const [personName, setPersonName] = React.useState(initialValue);


  useEffect(()=>{
    setPersonName(initialValue);
  },[JSON.stringify(initialValue)])

  const handleChange = (event) => {
    setPersonName(event.target.value);
    if(onChange){
      onChange(event.target.value);
    }

  };

  function commit(){
    if(onCommit){
      onCommit(personName);
    }
  }


  const menuList = useMemo(()=>{
    const menuList = [];
    const isArray = Array.isArray(list);
    let name;
    for(let x in list){
      name = isArray?list[x]:x;
      menuList.push(
          <MenuItem key={name} value={name}>
            <Checkbox color="primary" checked={personName.indexOf(name) > -1} />
            <ListItemText primary={list[x]} />
          </MenuItem>);
    }
    return menuList;
  },[list, personName])


  return (
      <FormControl className={classes.formControl}>
        {label &&
        <InputLabel>{label}</InputLabel>
        }
        <Select
          multiple
          value={personName}
          onClose={commit}
          onChange={handleChange}
          input={<Input/>}
          renderValue= {(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {menuList}
        </Select>
      </FormControl>

  );
}

export default function Filters({gym = "opossumTest", category="mediaTags", value="prof", onChange, onCommit}){

  const [list,setList] = useState([]);

  useEffect(()=>{
    request.get(`/${gym}/lists/${category}/${value}`).then(res=>{
      if(res.result && res.result.metadata){

        setList(res.result.metadata.values ||[]);
      }else{
        setList([])
      }
    })
  },[gym, category, value])

  return(
    <MultipleSelect label={value} list={list} onChange={onChange} onCommit={onCommit}/>
  )
}
