import React, {Fragment, useMemo, useState} from 'react';
import TableContainer from "@material-ui/core/TableContainer";
import withStyles from "@material-ui/core/styles/withStyles";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";

import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Box from "@material-ui/core/Box";

export default function JsonTable({
    header,
    data = [], keyName,
    beforeHeader,
    beforeRow,
    rowClicked,
    collapsedRow,
    Header=StyledHeader,
    Row=StyledRow,
    component
                               }){



    const headerList = useMemo(()=>{
        let list = header;
        if(!header && data && data[0]){
            list = Object.keys(data[0]);
        }
        return list || [];
    },[header, data]);
    
    
    const list = useMemo(()=>{
        const list = []
        let json;
        for(let i in data){
            json = data[i];
            const rows = headerList.map((header)=>{
                let value = json[header];
                if(beforeRow){
                    if(beforeRow[header]) {
                        value = beforeRow[header](value, i);
                    }else if(typeof beforeRow ==='function'){
                        value = beforeRow(header, value, i);
                    }
                }


                return ( <TableCell key={json[keyName] + "Row"+header}>{value}</TableCell>);
            })

            if(collapsedRow){
                list.push( <CollapsibleRow key={json[keyName] +"_"+i} cells={rows} colSpan={rows.length + 1} onClick={()=> rowClicked&& rowClicked(data[i],i)}>
                    {collapsedRow(json, i)}
                </CollapsibleRow>);
                continue;
            }
            list.push(
                <Row key={json[keyName]+"_"+i} onClick={()=>rowClicked && rowClicked(data[i], i)}>
                    {rows}
                </Row>
            );
        }

        return list;
    },[data])

    return(
        <TableContainer component={component}>
            <Table>
                <TableHead>
                    <Header>
                        {collapsedRow && <TableCell/>}
                        {
                            headerList.map((v,i)=>{
                                if(beforeHeader){
                                   v = beforeHeader(v, i);
                                }

                                return <TableCell key={v+"header"}>{v}</TableCell>;
                            })
                        }
                    </Header>
                </TableHead>
                <TableBody>
                    {list}
                </TableBody>
            </Table>
        </TableContainer>
    )

}


export function CollapsibleRow({children, cells, colSpan, Row=StyledRow, onClick}){

    const[open, setOpen] = useState(false);
    return (
        <Fragment>
            <Row onClick={onClick}>
                <TableCell>
                    <IconButton size={"small"} onClick={(e)=>{
                        e.stopPropagation();
                        setOpen(!open)
                    }}>
                        {open?<KeyboardArrowUpIcon/>:<KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>
                {cells}
            </Row>
            <TableRow>
                <TableCell style={{paddingBottom:0, paddingTop:0}} colSpan={colSpan}>
                    <Collapse in={open} timeout={"auto"} unmountOnExit>
                        <Box margin={1}>
                            {children}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    )
}


export const StyledRow = withStyles(theme=>({
    root:{
        "&:nth-of-type(odd)":{
            backgroundColor:theme.palette.action.hover
        },
        "&:hover":{
            backgroundColor:theme.palette.primary.light,
            color:theme.palette.primary.contrastText,
            cursor:"pointer"
        },
        "&:active":{
            backgroundColor:theme.palette.primary.dark,
        },
        "& > *":{
            borderBottom:"unset"
        }
    }
}))(TableRow)

export const StyledHeader = withStyles(theme=>({
    head:{
        backgroundColor:theme.palette.secondary.main,
        "& > *":{
            color:theme.palette.secondary.contrastText,

        }
    }
}))(TableRow);

export const BasicRow = withStyles(theme=>({
    root:{
        "& > *":{
            borderBottom:"unset"
        }
    }
}))(TableRow)

export const BasicHeader = withStyles(theme=>({
    head:{
        "& *":{
            fontWeight:"bold"
        }
    }
}))(TableRow)