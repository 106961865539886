
export const ChildrenComponent = (t)=> '<span data-gjs-type="default" data-gjs-editable="true" data-gjs-type="text" draggable="true" data-highlightable="1" class="" id="Children-content">' +
    `

<button onclick="onClick()" type="button" data-gjs-editable="true"  id="childrenButton"  class="mdc-button mdc-button--raised children-button">
    <div class="mdc-button__ripple" data-gjs-editable="false" data-gjs-layerable="false" data-gjs-selectable="false"></div>
  <span class="mdc-button__label" data-gjs-editable="true" data-gjs-layerable="false" data-gjs-selectable="true"  data-gjs-removable="false"  data-gjs-draggable="false">${t("addChild")}</span>
</button>
<br/>
<script>
    mdc.ripple.MDCRipple.attachTo(document.querySelector('.children-button'))
</script>
<div style="width:100%; display:flex; flex-wrap:wrap">
<div class="childTemplate mdc-card mdc-card--outlined" style="">
    <label data-gjs-removable="false">${t("firstname")}<input data-gjs-removable="false" name="child.firstname"/></label>
    <label data-gjs-removable="false">${t("lastname")}<input data-gjs-removable="false" name="child.lastname"/></label>
    <label data-gjs-removable="false">${t("dateOfBirth")}<input data-gjs-removable="false" type="date" name="child.birthdate"/></label>
</div>
</div>
`+
    '</span>';


export const ChildrenBlock =(t)=>({
    label: `<span>
<div class="fa fa-user-plus" style="font-size:2em"></div><br/><br/>
${t("addChild")}
</span>`,
    attributes:{
        style:""
    },
    content: ChildrenComponent(t),
    category: 'Forms'
})