import React, {useEffect, useState} from "react";

import PropTypes from "prop-types";
import {camelToLabel} from "./Utils";
import {makeStyles} from "@material-ui/core/styles";

JsonDisplay.propType = {
  name: PropTypes.string.isRequired,
  labels: PropTypes.object,
  values: PropTypes.object.isRequired,
  conditions: PropTypes.object,
  rowClicked: PropTypes.object,
  renderRowValue: PropTypes.object,
  globalCondition: PropTypes.func,
  formatValue: PropTypes.object,
  globalFormat: PropTypes.func,
  ignoreKey: PropTypes.array

};

const useStyles = makeStyles((theme) => ({
  root:{
    '& td':{
      borderTop:"1px solid #ebebeb!important",
    },
    '& input':{
      border:"none",
      width:"auto",
      padding:"0",
      textAlign:"inherit",
      fontSize:"inherit"
    }
  },
  boxFill:{
    width:"100%",
    "& $label":{
      textAlign:"left",
    },
    "& $value":{
      textAlign:"right"
    }
  },
  center:{
    width:"inherit",
    "& $label":{
      textAlign:"right"
    },
    "& $value":{
      textAlign:"left"
    }
  },
  label:{
    color:"#95989a",
    paddingRight:"0.2em"
  },
  value:{
  }

}));

export default function JsonDisplay({name, values, labels, rowClicked, renderRowValue, conditions, globalCondition,
                                      formatValue, globalFormat, ignoreKey, boxFill, center, updateHook= [], globalLabel}){

  const classes = useStyles();

  const [rows, setRows] = useState([]);

  useEffect(renderRows,[values, labels,...updateHook]);


  function renderRows(){
    const list = [];

    let value;
    for(let x in values){
      if(ignoreKey && ignoreKey.includes(x)){
        continue;
      }
      if(globalCondition && globalCondition(values[x]) === false){
        continue;
      }
      if(conditions && conditions[x] && conditions[x](values[x]) === false){
        continue;
      }
      value = (formatValue && formatValue[x])? formatValue[x](values[x]): values[x];
      if(globalFormat){
        value = globalFormat(value);
      }

      list.push(
        <tr key={x} className={name+"Row " + ((rowClicked && rowClicked[x])? "clickable":"")} onClick={rowClicked && rowClicked[x]? rowClicked[x]:null}>
          <td className={name+"Label "+classes.label}>
            <label>
              {(labels && labels[x])? labels[x]:
                  (globalLabel?globalLabel(x):camelToLabel(x))
                  }:
            </label>
          </td>
          <td className={name+"Value "+classes.value}>
            {(renderRowValue && renderRowValue[x])? renderRowValue[x](value):
              value}
          </td>
        </tr>);
    }

    setRows(list);
  }

  return(
    <table className={classes.root+" "+ name + " JsonDisplay " + (boxFill?classes.boxFill:"") + (center?classes.center:"")}>
      <tbody>
      {rows}
      </tbody>
    </table>
  )

}
