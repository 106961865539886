import {useEffect, useState} from "react";

export default function useBuffering(value, callback, timeout = 500){
    const [buffering, setBuffering] = useState(false);
    const [state, setState] = useState(value);
    const [stateOnCall, setStateOnCall] = useState(value);


    useEffect(buffer, [state]);

    useEffect(check, [buffering]);

    useEffect(call, [stateOnCall]);


    function check(){
        if(!buffering && state !== stateOnCall) {
            caller();
        }
    }

    function caller(){
        setBuffering(false);
        setStateOnCall(state);
    }

    function buffer(){

        if(!buffering){
            setBuffering(true);
            setTimeout(caller, timeout);
        }
    }


    function call(){
        if(callback) {
            callback();
        }
    }


    return [state, setState];
}