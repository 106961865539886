export const emailValidation ={
    Regex:"^(([^<>()[\\]\\\\.,;:\\s@\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$",
    RegexMessage:"Invalid E-mail Address"
}
export const confirmEmailValidation = {
    MatchInput:"email",
    MatchMessage:"Your e-mail does not match"//TODO translate
}

export const phoneValidation={
    Regex:`^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$`,
    RegexMessage :"Invalid phone number"
}