import React, {useEffect, useState} from 'react';
import {State} from "../common/components/Response";
import {useLoggedInRequest} from "../common/components/useRequest";
import Login from "../common/components/Login";


export default function FormViewer({gym, id}){

    const[filledForm,setFilledForm]= useState("");

    useLoggedInRequest(gym,[id])
        .call((request)=>{
            request.get("/data/"+ id +"/view").then((res) => {
                if(res.state === State.SUCCESS && res.result){
                    setFilledForm(res.result);
                }else{
                    setFilledForm('<h1> FORM NOT FOUND</h1>');
                }
            })
        }).unauthorized((res)=>{
            window.location.href = "/"+gym+"/login?redirect="+window.location.href;
        })

    return (
        <>
            <Login gym={gym}/>
            <link href="/css/base.css" rel="stylesheet"/>
            <link href="https://unpkg.com/material-components-web@latest/dist/material-components-web.min.css" rel="stylesheet"/>
            <script src="https://unpkg.com/material-components-web@latest/dist/material-components-web.min.js"/>
            <div dangerouslySetInnerHTML={{__html:filledForm}}/>
        </>
    )

}
