import React, {useEffect, useState} from 'react';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

export default function SnackbarAlert({title, message, duration, severity="error"}){
    const [open, setOpen] = useState(false)

    useEffect(()=>{

        setOpen(!!message);
    },[message])

    function handleClose(){
        setOpen(false);
    }

    return (
        <Snackbar open={open} autoHideDuration={duration} onClose={handleClose}>
            <Alert onClose={handleClose} severity={severity}>
                {title && <b>{title}: </b>}
                {message}
            </Alert>
        </Snackbar>
    )
}