import SelectAccreditation from "../SelectAccreditation";
import React from "react";

export default function AccreditationComponent(ID, category, title, t) {
    const name="accreditation."+category+":"+title;
    return `
${category}, ${title}: <br/>
<span style="padding:0.5em" data-gjs-copyable="false">
<input type="number" hidden="true" disabled="true" value="${ID}" name="${name}.ID" data-gjs-copyable="false"/>
<label data-gjs-draggable="false" data-gjs-removable="false">
    ${t("failed")}
    <input type="radio" value="failed" name="${name}.result" data-gjs-removable="false"/>
</label>
<label data-gjs-draggable="false" data-gjs-removable="false">
    ${t("confirmed")}
    <input type="radio" value="confirmed" name="${name}.result" data-gjs-removable="false"/>
</label>
</span>
`
}


export const AccreditationModel =(gym, t, confirm)=>({
    model:{
        defaults:{
            tagName:"div",
            droppable:"false",
            components:(model)=>{
                confirm({
                    Type:SelectAccreditation,
                    gym:gym,
                    key:"AccreditationSelection",
                }).then((res)=>{
                    if(res && res.ID) {
                        model.components(AccreditationComponent(res.ID, res.category, res.title, t))
                    }else{
                        model.remove();
                    }
                })
                return <div/>

            }
        },

    }

});


export const AccreditationBlock={
    label: `<span>
<div class="fa fa-graduation-cap" style="font-size:2em"></div><br/><br/>
Accreditation
</span>`,
    content:{
        type:'accreditation',
    },
    category: 'Forms'
}