import React, {useCallback, useEffect, useRef, useState} from "react";
import {Stream} from "../index";
import {useKeyPress} from "../index";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import {paymentRequest, request} from "../index";
import {makeStyles, ThemeProvider} from '@material-ui/core/styles';
import {State} from "../index";
import Dialog from "@material-ui/core/Dialog";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import {useTranslation} from "react-i18next";
import Link from "@material-ui/core/Link";
import useGymTheme from "./Theme";
import {useCookies} from "react-cookie";
import {useQuery} from "../index";
import {ShowLoading} from "../index";
import Grid from "@material-ui/core/Grid";
import useMounted from "./useMounted";
import Container from "@material-ui/core/Container";

const isChild = window.parent.location !== window.location;

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
        paper: {
            height: 300,
            width: 200,
        },
        display:"block",
        textAlign:"center",
        padding:theme.spacing(3)
    },
    form:{
        display:"block",
        textAlign:"center",
        margin:"auto"
    },
    logo:{
        width:200
    },
    login:{
        position:"absolute",
        top:"1em",
        right:"1em"
    },
    options:{
        display:"flex",
        width:"90%",
        margin:"auto",
        maxWidth:"500px"
    },
}));

export function checkCookie(){
    // Quick test if browser has cookieEnabled host property
    //if (navigator.cookieEnabled) return true;
    // Create cookie
    document.cookie = "cookietest=1; samesite=strict; "+(process.env.NODE_ENV === 'production'?'secure;':";");
    let ret = document.cookie.indexOf("cookietest=") !== -1;
    // Delete cookie
    document.cookie = "cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT;";
    return ret;
}

export function useSession(key){
    const [query,setQuery, removeQuery] = useQuery(key);
    const [cookie, setCookie, removeCookie] = useCookies([key]);
    const [state, setState] = useState(undefined);
    const [cookiesEnabled] = useState(checkCookie());

    useEffect(()=>{
        console.log("Cookies Enabled", cookiesEnabled)
    },[cookiesEnabled])

    useEffect(()=>{
        if(query[key]){
            setState(query[key]);
        }
    },[query]);

    useEffect(()=>{
        if(cookie[key]){
            setState(cookie[key])
        }
    },[cookie]);


    useEffect(()=>{
        if(window.parent){
            window.parent.postMessage({[key]:state},'*');
        }
    },[state])


    function setter(value){
        setCookie(key,value,{path:"/", sameSite:"strict", secure: process.env.NODE_ENV === 'production'})
        if(!cookiesEnabled){
            setQuery(key, value);
        }
    }

    function remove(){
        removeQuery(key);
        removeCookie(key,{path:"/"});
        removeCookie(key,{});
        if(window.parent){
            window.parent.postMessage("logout", '*');
        }
    }

    return [state, setter, remove];
}


export function useLogin(onLogin, onLogout, onUnauthorized){
    const [token, setToken] = useSession("token");
    const [user, setUser] = useState({});
    const [isLoggedIn, setIsLoggedIn]= useState(undefined);

    useEffect(()=>{
        function unauthorized(){
            console.log("unauthorized")
            if(onUnauthorized){
                onUnauthorized();
            }
        }
        Stream.on("unauthorized",unauthorized);
        return ()=>Stream.removeListener("unauthorized", unauthorized)
    },[onUnauthorized])


    useEffect(()=>{

        if(token && token !== "" && token !== "undefined"){
            setIsLoggedIn(true);
            request.setAuthorization(token);
            paymentRequest.setAuthorization(token)
            //onSuccess({}, token);
            if(onLogin){
                return onLogin(user, token);
            }
        }else if(onLogout){
            setIsLoggedIn(false);
            return onLogout();
        }else{
            setIsLoggedIn(false);
        }

    },[token, user, onLogin, onLogout])

    function login(user,token){
        setToken(token)
        setUser(user);
    }


    return [login, isLoggedIn]
}

export default function Login({gym, onLogin, show = true, onCloseRequest, closeable = false, setShow, preventDefault=false}){
    const [t] = useTranslation('common')

    const classes = useStyles();
    const theme = useGymTheme(gym)
    const [shown, setShown] = useState(show);
    const [onSuccess, isLoggedIn]  = useLogin(handleLogin, onLogout, unauthorized);

    const mounted = useMounted();


    useEffect(()=>{
        if(mounted.current) {
            setShown(show);
        }
    },[show])

    useEffect(()=>{
        if(setShow){
            setShow(shown);
        }
    },[shown])


    function unauthorized(){
        setShown(true);
    }

    function handleLogin(user, token){
        setShown(false);
        if(onLogin){
            onLogin(user,token);
        }
    }

    function onLogout(){
        if(!preventDefault){
            setShown(true)
        }
    }



    return (
        <div>
            <ThemeProvider theme={theme}>
                {(isLoggedIn) ? <Logout gym={gym}/> :
                    <Button className={classes.login} onClick={() => setShown(true)}>
                        {t('login')}
                        <AccountCircleIcon/>
                    </Button>
                }
                <Dialog
                    open={shown}
                    onClose={()=>{
                        if(closeable){
                            setShown(false);
                        }
                        onCloseRequest && onCloseRequest();
                    }}
                    className={"userModal"}
                >
                    <Paper className={classes.root} elevation={3}>

                        <img className={classes.logo} src={"/api/"+gym+"/image"} alt={"Logo"}/>

                        <LoginForm gym={gym} onLogin={onSuccess}/>

                    </Paper>
                </Dialog>
            </ThemeProvider>
        </div>
    );
}


export function LoginForm({gym, onLogin}){
    const classes = useStyles();
    const [t] = useTranslation('common');
    useKeyPress("Enter", keyListener);
    const [login, setLogin]  = useState("");
    const [password, setPassword] = useState("");
    const[error, setError] = useState(<div/>);
    const [loading, setLoading] = useState(false);
    const [{redirect}] = useQuery();
    const [token, setToken] = useSession("token");




    async function keyListener(){
        await submitLogin();
    }


    function handleChange(e){
        const name = e.target.name;
        const value = e.target.value;
        switch(name){
            case "login":
                setLogin(value);
                break;
            case "password":
                setPassword(value);
                break;
            default:
        }
        setError(<div/>);
    }

    async function submitLogin(){
        Stream.emit("user.login");
        setLoading(true);
        try{
            let res= await request.post("/"+gym+"/login", {login:login, password:password});

            if(res.state === State.SUCCESS && res.result != null){
                if(res.result.user) {
                    res.result.user.id = res.result.user.id || res.result.user.ID;
                }
                if(onLogin){
                    onLogin(res.result.user, res.result.token);
                }else if (redirect){
                    setToken(res.result.token);//TODO use session instead
                    window.location.href = redirect+(res.result.token?"?token="+res.result.token:"");
                }

                paymentRequest.setAuthorization(res.result.token)
                request.setAuthorization(res.result.token);


            }else{
                setError(<p className="error">{t('invalidCredentials')}</p>);
            }
        }catch(e){

            setError(<p className={"error"}>{t('anErrorOccurred')}</p>)
        }finally {
            Stream.emit("user.logged.in");
            setLoading(false);
        }
    }

    return(
        <form className={classes.form}>
            <TextField
                label={t('username')}
                name="login"
                id={"loginusername"}
                fullWidth
                onChange={handleChange}
            />

            <br /><br />
            <TextField
                label={t('password')}
                id={"loginpassword"}
                type="password"
                name="password"
                fullWidth
                onChange={handleChange}
            />
            <br /><br />
            {error}
            <ShowLoading loading={loading}>
                <Button variant="outlined" size="large" color="primary" onClick={submitLogin} fullWidth>
                    {t('login')}
                </Button>
            </ShowLoading>
            <br/>
            {/*<Link id={"selectGym"} className="link" to={"/"}>
                            Select another gym
                        </Link>
                        <br/>
                        */
                <Link id={"forgotPassword"} className={"link"} href={"password/reset"}>
                    {t("forgotPasswordOrUsername")}
                </Link>
            }

        </form>
    )

}

export function LoginPage({gym}){
    const classes = useStyles();

    return (
        <Container maxWidth={"xs"}>
        <Paper className={classes.root} elevation={3}>

            <img className={classes.logo} src={"/api/"+gym+"/image"} alt={"Logo"}/>

            <LoginForm gym={gym}/>

        </Paper>
        </Container>
    )
}


export function useLogout({gym}){
    const [, ,remove] = useSession("token");
    function logout(){
        remove();
        paymentRequest.setAuthorization(null)
        request.setAuthorization(null);
        request.post((gym?"/"+gym:"")+"/logout").finally(()=>{
            window.location.reload();
        })
    }
    return logout;
}


export function Logout({gym}){
    const [t] = useTranslation("common")
    const classes = useStyles()

    const logout = useLogout({gym});


    return(
        <Button className={classes.login} onClick={logout}>
            {t('logout')}
        </Button>
    )
}


export function Disconnected({gym, redirectUrl}){
    const classes = useStyles();
    const [links, setLinks] = useState({})
    const [t] = useTranslation("common")

    useEffect(()=>{
        request.get("/"+gym+"/links").then(res=>{
            setLinks(res.result);
        })
    },[gym])

    const redirect = useCallback(()=>{
        if(links && links.register){
            window.open(links.register,"_blank");
        }else{
            window.location.href = "/"+gym+"/register?redirect="+redirectUrl+"#register"
        }
    },[links,gym])


    return(
        <Grid container spacing={2} className={classes.options}>
            <Grid item xs={12} sm={6}>
                <Button fullWidth color={"primary"} variant={"contained"} onClick={redirect}>
                    {t("register")}
                </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Button fullWidth color={"primary"} variant={"outlined"} href={"/"+gym+"/register?redirect="+redirectUrl+"#login"}>
                    <AccountCircleIcon/> {t("login")}
                </Button>
            </Grid>
        </Grid>
    )
}
