import React, {Suspense} from "react";
import './App.css';
import {Router} from "@reach/router";
import FormsLoader from "./components/FormsLoader";
import FormBuilder from "./components/FormBuilder";
import {ShowLoading} from "./common";
import Form from "./components/Form";
import {LoginPage} from "./common/components/Login";
import {GymTheme} from "./common/components/Theme";
import FormViewer from "./components/FormViewer";
import FilledFormLoader from "./components/FilledFormLoader";
import SelectAccreditation from "./components/SelectAccreditation";

function App() {


  return (
    <div className="App">
        <Suspense
            fallback={<ShowLoading loading={true}/> }
        >
        <Router className={"App"}>
            <GymTheme path={"/:gym"}>
                <SelectAccreditation path={"/accreditations"}/>
                <FormsLoader path = {"/loader/"}/>
                <FormBuilder path={"/builder/:id"}/>

                <FilledFormLoader path={"/view/list/:formId"}/>
                <FilledFormLoader path={"/view/list"}/>
                <FormViewer path={"/view/:id"}/>

                <Form path={"/new/:id"}/>
                <LoginPage path={"/login"}/>
            </GymTheme>
        </Router>
        </Suspense>
    </div>
  );
}


export default App;
