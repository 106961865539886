import TextField from "@material-ui/core/TextField";
import Popover from "@material-ui/core/Popover";
import Transition from "react-transition-group/Transition";
import JsonTable from "./Table";
import Paper from "@material-ui/core/Paper";
import React, {useState} from "react";
import useBuffering from "../utils/useBuffering";
import {useLoggedInRequest} from "./useRequest";
import {State} from "./Response";
import makeStyles from "@material-ui/core/styles/makeStyles";


const useStyles = makeStyles(theme=>({
    popover:{
        maxHeight:'50vh'
    }
}))

export default function UserSearch({gym, userSelected}){
    const classes = useStyles();
    const [search, setSearch]  = useBuffering("", fetch);
    const [userList, setUserList] = useState([])



    const [anchor, setAnchor] = useState(undefined);

    const req = useLoggedInRequest(gym)
        .unauthorized((res)=>{
            console.log(res);
        });

    function fetch(){
        if(search && req.request){
            req.request.get("/users/search/"+search,{keys:'firstname,lastname'}).then(res=>{
                if(res.state === State.SUCCESS && res.result){
                    setUserList(res.result);
                }else{
                    setUserList([])
                }
            })
        }
    }


    function createAnchor(e){
        if(!anchor){
            setAnchor(e.target)
        }
    }

    return(
        <div>
            <TextField label={"search"} onChange={e=>{
                setSearch(e.target.value)
                createAnchor(e);
            }}
                       onFocus={createAnchor}
            />
            <Popover
                className={classes.popover}
                open={!!anchor}
                anchorEl={anchor}
                onClose={()=>setAnchor(undefined)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                TransitionComponent={Transition}
                disableAutoFocus
                disableEnforceFocus
                disableRestoreFocus
            >

                <JsonTable header={["firstname", "lastname", "user_email", "birthdate"]}
                           data={userList}
                           keyName={"ID"}
                           component={Paper}
                           rowClicked={(json)=>{
                               if(userSelected){
                                   setAnchor(undefined);
                                   userSelected(json);
                               }
                           }}
                />
            </Popover>
        </div>
    )
}