import React, {useEffect} from 'react';
import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Link from "@material-ui/core/Link";
import {useSession} from "../components/Login";
import useQuery from "../utils/useQuery";

i18next
  .use(XHR)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    interpolation:{escapeValue:false},
    load:'languageOnly',
    fallbackLng:"fr",//TODO
    backend:{
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    },
    order: ['querystring', 'cookie', 'sessionStorage'],//, 'navigator', 'htmlTag', 'path', 'subdomain'],TODO

    // keys or params to lookup language from
    lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
    lookupSessionStorage: 'i18nextLng',
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,

    // cache user language on
    caches: ['cookie'],
    // optional htmlTag with lang attribute, the default is:
    htmlTag: document.documentElement,

    // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
    cookieOptions: {sameSite:'none', secure:process.env.NODE_ENV === 'production', path:"/"}
  })


export default i18next;

const useStyle= makeStyles(theme=>({
  root:{
    width:'100%',
    textAlign:'center',
    fontSize:'0.8em',
    marginBottom:'1em'
  }
}));
export function LanguageSelector(){
  const classes = useStyle();
  const [, setQuery] = useQuery("lng");
  const [] = useSession("lng");

  function changeLanguage(lng){
      setQuery("lng", lng);
      //i18next.changeLanguage(lng);
  }
  return (
    <footer className={classes.root} >
        {process.env.NODE_ENV !== 'production'?
            (<div>
                <Link href={""} onClick={() =>changeLanguage("fr")}>Français</Link> |
                <Link href={""} onClick={() => changeLanguage('en')}>English</Link>
            </div>):
            <div/>
        }
    </footer>
  )
}
