import React, {useRef, useState} from "react";
import Button from "@material-ui/core/Button";
import {Dialog, TextField} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {useLoggedInRequest} from "../common/components/useRequest";
import {State} from "../common/components/Response";
import {useTranslation} from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyle = makeStyles(theme=>({
    root:{
        textAlign:"center",
        margin:"2em"
    }
}))

export default function FormCreator({gym}){

    const classes = useStyle();
    const [t] = useTranslation("forms")
    const {request} = useLoggedInRequest(gym)
    const [open, setOpen] = useState(false);
    const newForm = useRef({title:"", description:""});

    function handleClose() {
        setOpen(false);

    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    function verify(form) {

        for(let key in form.current){
            if(key!=='document'){
                if(form.current[key].length===0){
                    return false;
                }
            }

        }
        return true;
    }

    function create(form){

        let conform = verify(form);
        if(conform){
            createForm(createJsonFormat(form));
        }else{
            window.alert(t("Please fill all fields"));
        }

    }


    function createForm(form_json){
        request.post("/forms/", form_json).then((res)=> {
            console.log(res)
            if(res.state === State.SUCCESS && res.result && res.result.formId){

                window.location.href = "/"+gym+"/builder/"+res.result.formId
            }else if(res.state === State.FAILED && res.result && res.result.message === "duplicate"){
                window.alert(t("This form already exist"));
            }

        })

    }

    function createJsonFormat(ref_newForm){

        return {
            title: ref_newForm.current.title,
            description: ref_newForm.current.description,
            html: ref_newForm.current.document
        };
    }

    return(
        <div className={classes.root}>
            <h2> {t("addNewForm")}</h2>
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                +
            </Button>
            <Dialog fullWidth
                    maxWidth="sm" onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
                <Grid container justify = "center">
                    <div  className={"FormCreator"}>
                        <h1>{t("Create a new form")} </h1>
                        <TextField  fullWidth={true} label={t("title")}
                                    onChange={(e)=>newForm.current.title = e.target.value}/> <br/>
                        <TextField  fullWidth={true} label={t("description")}
                                    onChange={(e)=>newForm.current.description = e.target.value}/> <br/>


                        <Button fullWidth={true} variant="outlined" color="default" onClick={() => create(newForm)}> {t("Create")}  </Button>

                    </div>
                </Grid>

            </Dialog>
        </div>
    )
}