import {useCallback, useEffect, useRef, useState} from 'react';
import {useLogin} from "./Login";
import {Request, request} from "./Requests"
import {useQuery} from "../index";
import {State} from "./Response";

export default function useRequest(gym, token, dependencies = []){
    const [state, setState ] = useState(undefined);

    const hasChanged = useRef(false);

    const unauthorized = useRef(undefined);

    const ob1 = useRef({})

    const [changed, isChanged] = useState(false);

    useEffect(()=>{
        isChanged(true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },dependencies);

    ob1.current.call = useCallback((callback) => {
        if (state && callback && changed) {
            console.log("Calling");
            isChanged(false);
            //setCallback(callback);
            callback(state);
            //console.log("Called");
            //hasChanged.current = false;
        }

        return ob1.current;

    }, [state, changed]);



    ob1.current.request = state;
    ob1.current.unauthorized = (callback)=>{
        unauthorized.current = callback;
        return ob1.current;
    }


    useEffect(()=>{
        if(gym) {
            const request = new Request("/api/"+gym, gym, token,(res)=>{
                if(unauthorized.current){
                    unauthorized.current(res);
                }
            });

            isChanged(true);
            setState(request);
            return () => {
                request.deregister();
                request.cancelOnRedirect();
            }
        }
    },[gym, token]);

    return ob1.current

}

export function useLoggedInRequest(gym, dependencies = []){
    const [token, setToken ] = useState(undefined);
    const [state, setState] = useState(undefined);
    const req = useRequest(state, token, dependencies);
    req.unauthorized(()=>{
        window.location.href = "/"+gym+"/login?redirect="+window.location.href;
    })

    const onLogin = useCallback((user, token) =>{
        setState(gym);
        setToken(token);
    },[gym]);



    const [login, isLoggedIn] = useLogin(onLogin);


    const [{oposToken},,remove] = useQuery();

    useEffect(()=>{
        if(!isLoggedIn && oposToken){
            //remove("oposToken")

            request.post("/"+gym+"/login/token",{token:oposToken})
                .then((res)=>{
                    if(res.state === State.SUCCESS && res.result){
                        login(res.result.user, res.result.token)
                    }
                }).catch(e=>console.log(e))
        }
    },[oposToken,isLoggedIn])


    return req
}

