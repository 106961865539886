export const InputModal=({name = "", validation={}, type})=> `<div class="container" style=" width: 80%; display: block; align-content: center;">
                    <br>
                        <div style="width: 34%; float:left">
                            <div style="display: inline-block; align-content: center">
                                <div class="gjs-trt-traits gjs-one-bg gjs-two-color" >
                                    <div class="gjs-trt-trait gjs-trt-trait--text">
                                        <div class="gjs-label-wrp" data-label="">
                                            <div class="gjs-label" title="Name">Name*</div>
                                        </div>
                                        <div class="gjs-field-wrp gjs-field-wrp--text" data-input="">
                                            <div class="gjs-field gjs-field-text" data-input="">
                                            <input type="text" name="name" placeholder="" value="${name||""}"></div>
                                        </div>
                                    </div>
                                     ${type==='Radio'?
                                     `<div class="gjs-trt-trait gjs-trt-trait--text">
                                        <div class="gjs-label-wrp" data-label="">
                                            <div class="gjs-label" title="Value">Value *</div>
                                        </div>
                                        <div class="gjs-field-wrp gjs-field-wrp--text" data-input="">
                                            <div class="gjs-field gjs-field-text" data-input="">
                                            <input type="text" name="value" required placeholder=""></div>
                                        </div>
                                    </div>`
                                    :""}
                                    <div class="gjs-trt-trait gjs-trt-trait--text">
                                        <div class="gjs-label-wrp" data-label="">
                                            <div class="gjs-label" title="Placeholder">Placeholder</div>
                                        </div>
                                        <div class="gjs-field-wrp gjs-field-wrp--text" data-input="">
                                            <div class="gjs-field gjs-field-text" data-input="">
                                            <input type="text" name="placeholder" placeholder=""></div>
                                        </div>
                                    </div>
                                    <div class="gjs-trt-trait gjs-trt-trait--select">
                                        <div class="gjs-label-wrp" data-label="">
                                            <div class="gjs-label" title="Type">Type</div>
                                        </div>
                                        <div class="gjs-field-wrp gjs-field-wrp--select" data-input="">
                                            <div class="gjs-field gjs-field-select">
                                                <select name ='type'>
                                                    <option value="text">Text</option>
                                                    <option value="email">Email</option>
                                                    <option value="password">Password</option>
                                                    <option value="number">Number</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="gjs-trt-trait__wrp gjs-trt-trait__wrp-required">
                                        <div class="gjs-trt-trait gjs-trt-trait--checkbox">
                                            <div class="gjs-label-wrp" data-label="">
                                                <div class="gjs-label" title="Required">Required</div>
                                            </div>
                                            <div class="gjs-field-wrp gjs-field-wrp--checkbox" data-input="">
                                                <label class="gjs-field gjs-field-checkbox" data-input="">
                                                <input name="required" type="checkbox" placeholder="" value="true">
                                                    <i class="gjs-chk-icon"></i>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="width: 32%; float:left"> 
                        <div style="display: inline-block; align-content: center">
                                <div class="gjs-trt-traits gjs-one-bg gjs-two-color" >
                                    <div class="gjs-trt-trait gjs-trt-trait--text">
                                        <div class="gjs-label-wrp" data-label="">
                                            <div class="gjs-label" title="Regex">Regex</div>
                                        </div>
                                        <div class="gjs-field-wrp gjs-field-wrp--text" data-input="">
                                            <div class="gjs-field gjs-field-text" data-input="">
                                            <input type="text" name="Regex" placeholder="Regex pattern" value='${validation.Regex||""}'/></div>
                                        </div>
                                    </div>
                                    <div class="gjs-trt-trait gjs-trt-trait--text">
                                        <div class="gjs-label-wrp" data-label="">
                                            <div class="gjs-label" title="MatchInput">Match Input</div>
                                        </div>
                                        <div class="gjs-field-wrp gjs-field-wrp--text" data-input="">
                                            <div class="gjs-field gjs-field-text" data-input="">
                                            <input type="text" name="MatchInput" placeholder="Input Name"></div>
                                        </div>
                                    </div> 
                                    <div class="gjs-trt-trait gjs-trt-trait--text">
                                        <div class="gjs-label-wrp" data-label="">
                                            <div class="gjs-label" title="Max Length">Max Length</div>
                                        </div>
                                        <div class="gjs-field-wrp gjs-field-wrp--text" data-input="">
                                            <div class="gjs-field gjs-field-text" data-input="">
                                            <input type="text" name="MaxLength" placeholder="Max input Length"></div>
                                        </div>
                                    
                                    </div> 
                                </div>
                            </div>
                        
                        </div>
                        <div style="width: 34%; float:left">
                           <div style="display: inline-block; align-content: center">
                           
                           <div class="gjs-trt-trait gjs-trt-trait--text">
                                        <div class="gjs-field-wrp gjs-field-wrp--text" data-input="">
                                            <div class="gjs-field gjs-field-text" data-input="">
                                            <input type="text" name="RegexMessage" placeholder="Regex Error Message" value="${validation.RegexMessage||""}"></div>
                                        </div>
                                    </div> 
                           <div class="gjs-trt-trait gjs-trt-trait--text">
                                        <div class="gjs-field-wrp gjs-field-wrp--text" data-input="">
                                            <div class="gjs-field gjs-field-text" data-input="">
                                            <input type="text" name="MatchMessage" placeholder="Match Input Error Message"></div>
                                        </div>
                                    </div>          
                           <div class="gjs-trt-trait gjs-trt-trait--text">
                                        <div class="gjs-field-wrp gjs-field-wrp--text" data-input="">
                                            <div class="gjs-field gjs-field-text" data-input="">
                                            <input type="text" name="MaxLengthMessage" placeholder="Max Length Error Message"></div>
                                        </div>
                                    </div> 
                            </div>
                        
                        </div>
                        <div > 
                        <div style="display: inline-block; align-content: center">
                                <div class="gjs-trt-traits gjs-one-bg gjs-two-color" >
                                    <div class="gjs-trt-trait gjs-trt-trait--text">
                                        <div class="gjs-label-wrp" data-label="">
                                            <div class="gjs-label" title="MetaMaker">User Metadata Key</div>
                                        </div>
                                        <div class="gjs-field-wrp gjs-field-wrp--text" data-input="">
                                            <div class="gjs-field gjs-field-text" data-input="">
                                            <input type="text" name="metaMaker" placeholder="Metadata Key"/></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                          
                </div>
            `;