const CSS_NUMBER_REGEX = /([0-9]+(?:\.|)[0-9]*)(.*|%)/i;

export default function QuickStylesPlugin(editor){



    editor.StyleManager.addSector('quickStyles',{
        name:'Quick Styles',
        open:true,

    },{at:0});

    editor.StyleManager.addSector('usefulStyles',{
        name:'Styles',
        open:true,
        buildProps: ['background-color', 'color','font-size','text-align'],

    },{at:1})



    const defaultCreate = (faIcon)=>({props, change})=>{
        const el = document.createElement('div')
        el.className =" gjs-block gjs-one-bg gjs-four-color-h"
        const button = document.createElement("span")
        button.className="fa fa-"+faIcon;
        button.style='font-size:2em'
        el.onclick=(event)=>change({event})
        el.appendChild(button);
        return el;
    }

    function createStyleType({faIcon, style, name, styleManager = editor.StyleManager, section ='quickStyles', property='margin',
                                 create=defaultCreate(faIcon)}){
        const type = name.toLowerCase();
        styleManager.addType(type,{
            create: create,
            emit(a, b){
                if(typeof style === 'function'){
                    a.updateStyle(style(a,b),{complete:b.complete})
                }else{
                    a.updateStyle(style,{complete:b.complete})
                }
            }
        });

        styleManager.addProperty(section,{name, type, defaults:'none', property})
    }


    createStyleType({
        faIcon:'crosshairs',
        style:({props, setProps})=>{
            if(!props.value || props.value ==='auto' || props.value==='100%'){
                props.value = 'fit-content'
                setProps && setProps('value', 'fit-content')
            }
            return {'margin-left':'auto','margin-right':'auto', width:props.value}
        },
        name:'Center',
        property:'width'
    })
    createStyleType({
        faIcon:'text-width',
        style:({props,setProps})=>{
            props.value="100%";
            setProps && setProps('value')
            return {'margin-left':'0','margin-right':'0', width:'100%'}},
        name:'Full Width',
        property:'width'
    })
    createStyleType({
        name:"Elevate",
        faIcon:"adjust",
        property:'box-shadow',//TODO more elevations
        style:({props})=>{
            //console.log(editor.StyleManager.getModelToStyle());
            return { "border-radius": "var(--mdc-shape-medium, 4px)",
                "background-color": "var(--mdc-theme-surface, #fff)",
                "position": "relative",
                'box-shadow':"0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
                "overflow-y":"auto",
                "height":"fit-content",
                //"display": "flex",
                //"flex-direction": "column",
                "box-sizing": "border-box"}
        }
    })

    createStyleType({
        name:"Compress",
        faIcon:"compress",
        property:'width',//TODO more elevations
        style:({props, setProps})=>{
            let value = '50%';
            if(props.value && props.value.match){
                const match =props.value.match(CSS_NUMBER_REGEX);
                if(match && match.length >2){
                    value = (Number(match[1])/2)+match[2];
                }
            }
            props.value = value;
            setProps && setProps('value', value)
            return {width:value};
        }
    })

    createStyleType({
        name:"Clear",
        faIcon:"eraser",
        property:'width',
        style:({props, setProps,targets})=>{
            if(targets && targets.length){
                for(let x in targets){
                    console.log(targets[x].attributes);
                    if(targets[x].attributes && targets[x].attributes.style){
                        targets[x].attributes.style = {}
                    }
                }
            }
            return "auto"
        }
    })


    createStyleType({
        name:"Margin",
        property:'margin-top',
        style:({props, setProps,targets})=>{

            let value = 8;

            if(props.value && props.value!=='auto' && props.value !== 'none'){
                const [num] = getCssNumber(props.value);

                value = num !== undefined? num + 8: value;

            }

            setProps('value',value)
            return value + "px";
        },
        faIcon:"arrow-down"
    })


    createStyleType({
        name:"Padding",
        property:'padding',
        style:({props, setProps,targets})=>{

            let value = 8;

            if(props.value && props.value!=='auto' && props.value !== 'none'){
                const [num] = getCssNumber(props.value);

                value = num !== undefined? num + 8: value;

            }

            setProps('value',value)
            return value + "px";
        },
        faIcon:"arrows-alt"
    })

}

function getCssNumber(value){

    if(value && value.match){
        const match =value.match(CSS_NUMBER_REGEX);
        if(match && match.length >2){
            return [Number(match[1]), match[2]]
        }
    }

}