import React, { useState} from "react";
import '../css/formsLoader.css'
import  {useLoggedInRequest} from "../common/components/useRequest";
import Login from "../common/components/Login";
import FormCreator from "./FormCreator";
import SnackbarAlert from "../common/components/SnackbarAlert";
import {State} from "../common/components/Response";
import {useTranslation} from "react-i18next";
import useConfirmDialog, {ConfirmDialog} from "../common/utils/useConfirmDialog";
import JsonTable from "../common/components/Table";
import Paper from "@material-ui/core/Paper";
import {copyForm, deleteForm, publish} from "./FormBuilder";
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import IconButton from "@material-ui/core/IconButton";
import {Create, Delete, List, Preview, Publish} from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Card from "@mui/material/Card";
import NotFound from "../common/components/NotFound";

export default function FormsLoader({gym}){

    const [t] = useTranslation("forms")
    const [formList, setFormList] = useState([]);
    const [message, setMessage] = useState(undefined);
    const [dialog, confirm] = useConfirmDialog();
    const [viewDeleted, setViewDeleted] = useState(false);

    const [notFound, setNotFound] = useState(false);

    const fetch = (request)=>{
        request.get("/forms/",{viewDeleted}).then((res) => {
            if(res.result && res.result.length){
                setFormList(res.result);
                setNotFound(false);
                return;
            }else if (res.state ===State.FAILED){
                setMessage(t("couldNotFetchForms"))
                setNotFound(false);
            }else if (res.state === State.NOT_FOUND){
                setNotFound(true);
            }

            setFormList([])
        })
    }
    const req = useLoggedInRequest(gym,[viewDeleted])
        .call(fetch)
        .unauthorized((res)=>{
            if(res.result && res.result ==="wrongAccessLevel"){
                setMessage(t(res.result));
            }else{
                window.location.href = "/"+gym+"/login?redirect="+window.location.href;
            }
        })


    async function onClick(form){

        const res = await confirm({
            Type:ConfirmDialog,
            text:"Do you want to edit the form or view filled form list?",
            confirmText:"View filled form list",
            cancelText:"Edit form"
        })

        if(res === false){
            window.location.href="/"+gym+"/builder/"+form.formId
        }else if(res){
            window.location.href = "/"+gym+"/view/list/"+form.formId
        }
    }



    return (
        <div className={"App"}>
            {dialog}
            <Login gym={gym}/>
            <SnackbarAlert title={"Oops"} message={message}/>
            <br/>
            {notFound ?
                <div>
                    <Card>
                        <NotFound/>
                    </Card>
                </div>:
                <div>
                    <FormCreator gym={gym}/>
                    <FormControlLabel
                        control={<Checkbox onChange={() => setViewDeleted(!viewDeleted)} checked={viewDeleted}/>}
                        label={t("showDeleted")}
                    />

                    <JsonTable header={["formId", "title", "description", "version", "status", "options"]}
                               data={formList}
                               beforeHeader={t}
                               beforeRow={{
                                   status: t,
                                   options: (i, o) => {

                                       const {formId, version} = formList[o];

                                       return <span>
                            <IconButton variant="outlined" color="default" onClick={(e)=>{
                                e.stopPropagation();
                                window.location.href="/"+gym+"/builder/"+formId;
                            }} >
                            <Create/>
                        </IconButton>
                        <IconButton variant="outlined" color="default" onClick={(e) => {
                            e.stopPropagation();
                            publish(t, req.request, formId, version,gym)().finally(() => fetch(req.request))

                        }}>
                                <Publish/>
                            </IconButton>
                    <IconButton variant="outlined" color="default" onClick={(e) => {
                        e.stopPropagation()
                        window.open("/" + gym + "/new/" + formId)
                    }}>
                                <Preview/>
                            </IconButton>
                    <IconButton variant="outlined" color="default" onClick={(e) => {
                        e.stopPropagation();
                        window.open("/" + gym + "/view/list/" + formId)
                    }}>
                                <List/>
                            </IconButton>
                    <IconButton onClick={(e) => {
                        e.stopPropagation();

                        copyForm(gym, confirm, t, req.request, formId, version)().finally(() => fetch(req.request))
                    }} aria-label={t("copy")} aria-labelledby={t("copy")}>
                        <ContentCopyIcon fontSize={"small"}/>
                    </IconButton>

                               <IconButton onClick={(e) => {
                                   e.stopPropagation();
                                   deleteForm(t, req.request, formId)().finally(() => fetch(req.request))

                               }}>
                                   <Delete fontSize={"small"}/>
                               </IconButton>
                </span>
                                   }
                               }}
                               rowClicked={onClick}
                               keyName={"formId"}
                               component={Paper}
                    />


                </div>
            }
        </div>

    )
}

