import {confirmEmailValidation, emailValidation, phoneValidation} from "./validation";

export const WaiverComponent =(t)=> `
<div classname="participant" draggable="true" id="i49uh">
  <b id="ihlzx">Participant</b>
  <br id="if1qv4"/>
  <div id="idqf6" class="row">
    <div id="ifwre" class="cell">
      <label draggable="true" class="label">${t("firstname")}<input type="text" required draggable="true" placeholder="${t("firstname")}" name="firstname" class="input"/></label>
      <label draggable="true" class="label"> ${t("lastname")} <input draggable="true" data-highlightable="1" type="text" required="true" placeholder="${t("lastname")}" name="lastname" id="iyldp" class="input gjs-selected"/></label>
      <label id="i5ktaw" class="label"> ${t("email")}<input type="text" placeholder="${t("email")}" name="email" required data-gjs-validation='${JSON.stringify(emailValidation)}'/></label>
    </div>
    <div id="ickkg" class="cell">
      <label id="i6mbfk" class="label">${t("confirmEmail")} <input type="text" placeholder="${t("confirmEmail")}" name="confirmEmail" data-gjs-validation='${JSON.stringify(confirmEmailValidation)}'/></label>
      <label class="label" id="i76ps"> ${t("phone")} <input draggable="true" data-highlightable="1" type="text" placeholder="${t("phone")}" name="phone"  data-gjs-validation='${JSON.stringify(phoneValidation)}' data-gjs-metakey="USER_PHONE" class="gjs-selected"/></label>
      <label class="label" draggable="true" id="i9gh5">${t("dateOfBirth")}<input type="date" required id="birthday" name="birthday" placeholder="${t("dateFormat")}"/> </label>
    </div>
  </div>
</div>
`;
export const WaiverBlock=(t)=>({
    label: `<span>
<div class="fa fa-address-card" style="font-size:2em"></div><br/><br/>
Participant
</span>`,
    attributes:{
        style:""
    },
    content: WaiverComponent(t),
    category: 'Forms'
})


