import {useCallback, useEffect, useState} from "react";
import {parse, stringify} from 'querystring';


export function useLocation(){
  const [location, setLocation] = useState(window.location);


  useEffect(()=>{
    function update(){
      setLocation(window.location);
    }

    window.addEventListener("popstate", update);

    return()=> window.removeEventListener("popstate", update)

  },[])


  return location
}

export default function useQuery(){
    const location = useLocation();
    const [state, setState]  = useState({});


    useEffect(()=>{
        if(location.search){
            const query =parse(location.search.slice(1));

            setState(query);
        }

    },[location])


    function setQuery(query){
        const hash = window.location.hash;
        window.history.replaceState(null,'',
            window.location.pathname+"?"+stringify(query) +hash
        );
        if(hash){
            window.dispatchEvent(new HashChangeEvent("hashchange"));
        }
        setState(query);
    }

    function set(key, value){
       const query = {...state};
        query[key] = value;

        setQuery(query);
    }

    function remove(key){
      const query = {...state};
      delete query[key];

      setQuery(query);
    }

    return [state, set,remove]
}

export function useFragmentIdentifier(onHashChange=true){
  const location = useLocation();
  const [state, setState] = useState(undefined);

  const setHash = useCallback(()=>{
    setState(window.location.hash.slice(1))
  },[]);

  useEffect(()=>{
    function update(){
      if(onHashChange) {
        setHash();
      }
    }

    window.onhashchange = update;

    return()=> window.removeEventListener("hashchange", update)

  },[onHashChange])


  useEffect(setHash,[location])


  function set(value){
    window.history.replaceState(null,'',"#"+value)
  }


  return [state,set]
}
