import {WaiverBlock} from "./WaiverBlock";
import {ChildrenBlock} from "./ChildrenBlock";
import {AccreditationBlock} from "./AccreditationComponent";
import {confirmEmailValidation, emailValidation, phoneValidation} from "./validation";
import "@material/textfield/dist/mdc.textfield.css";
import {StructureBlock} from "./Structure";



/*
- large buttons for quick styles (center, shadows, cards...)
- meta presets (emergency contact, phone number...)
- meta trait
- finish validation traits
~ Clear Editor style
- https://github.com/artf/grapesjs/issues/324
~ Fix disappearing user meta
- Padding/margin quick Style
- Prettier inputs
- add flex style manager
- remove child
- Translate Blocks
- Copy Form
- Configs
- Resize  editor width
- Access Level Forms
- OPOS tokens
- fix Link Block
-Mongodb Case sensitivity
~ Validation error text (fix overflow auto);
- fix prefill children
- Send message to parent if 404
TODO
Post form through remote API
Limit search to purchased accreditation List
fix full screen
Translate default blocks
Save blocks in backend
better default font
Setup gym themes
Preview
version history
better media queries
Editor height
Save & publish
Save validation on input (not meta)
fix select block
Migrate forms to Mongodb?

 */

export default function GrapesPlugin(validation, t) {
    return (editor) => {
        //editor.runCommand('sw-visibility');
        editor.Panels.removePanel(PANELS);
        editor.Panels.addPanel(PANELS).get('buttons').add({
            id: 'save',
            label: t('save'),
            command: "save"
        });

        editor.I18n.addMessages({
            fr:{
                blockManager:{
                    labels:{
                       link:"Lien",
                        text:"Texte",
                        "map":"Carte",
                        "link-block":"Bloc-Lien",
                        "quote":"Citation",
                        "text-basic":"Section de texte",
                        "countdown":"Décompte",
                        "textarea":`<svg class="gjs-block-svg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path class="gjs-block-svg-path" d="M22,7.5 C22,6.6 21.5,6 20.75,6 L3.25,6 C2.5,6 2,6.6 2,7.5 L2,16.5 C2,17.4 2.5,18 3.25,18 L20.75,18 C21.5,18 22,17.4 22,16.5 L22,7.5 Z M21,17 L3,17 L3,7 L21,7 L21,17 Z"></path>
        <polygon class="gjs-block-svg-path" points="4 8 5 8 5 12 4 12"></polygon>
        <polygon class="gjs-block-svg-path" points="19 7 20 7 20 17 19 17"></polygon>
        <polygon class="gjs-block-svg-path" points="20 8 21 8 21 9 20 9"></polygon>
        <polygon class="gjs-block-svg-path" points="20 15 21 15 21 16 20 16"></polygon>
      </svg><div class="""gjs-block-label">Zone de texte</div>`,
                        "button":`<svg class="gjs-block-svg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path class="gjs-block-svg-path" d="M22,9 C22,8.4 21.5,8 20.75,8 L3.25,8 C2.5,8 2,8.4 2,9 L2,15 C2,15.6 2.5,16 3.25,16 L20.75,16 C21.5,16 22,15.6 22,15 L22,9 Z M21,15 L3,15 L3,9 L21,9 L21,15 Z" fill-rule="nonzero"></path>
        <rect class="gjs-block-svg-path" x="4" y="11.5" width="16" height="1"></rect>
      </svg><div class="gjs-block-label"> Bouton</div>`,
                        "label":`<svg class="gjs-block-svg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path class="gjs-block-svg-path" d="M22,11.875 C22,11.35 21.5,11 20.75,11 L3.25,11 C2.5,11 2,11.35 2,11.875 L2,17.125 C2,17.65 2.5,18 3.25,18 L20.75,18 C21.5,18 22,17.65 22,17.125 L22,11.875 Z M21,17 L3,17 L3,12 L21,12 L21,17 Z" fill-rule="nonzero"></path>
        <rect class="gjs-block-svg-path" x="2" y="5" width="14" height="5" rx="0.5"></rect>
        <polygon class="gjs-block-svg-path" fill-rule="nonzero" points="4 13 5 13 5 16 4 16"></polygon>
      </svg><div class="gjs-block-label">Libelé</div>`,
                        "input":`<svg class="gjs-block-svg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path class="gjs-block-svg-path" d="M22,9 C22,8.4 21.5,8 20.75,8 L3.25,8 C2.5,8 2,8.4 2,9 L2,15 C2,15.6 2.5,16 3.25,16 L20.75,16 C21.5,16 22,15.6 22,15 L22,9 Z M21,15 L3,15 L3,9 L21,9 L21,15 Z"></path>
        <polygon class="gjs-block-svg-path" points="4 10 5 10 5 14 4 14"></polygon>
      </svg><div class="gjs-block=label">Champ</div>`
                    }
                }
            }
        })

        const blockManager = editor.BlockManager;

        editor.DomComponents.addType('LineBreak',{
            isComponent: el=> {
                el.innerHTML &&   el.innerHTML.replace(/¶/g,"\n");
                if(el.tagName ==='BR'){


                    return {type: 'LineBreak'}
                }
            },
            view:{
                tagName:'span',
                onRender: ({el, model})=>{
                    el.innerHTML = " &para;<br/>"
                    el.style="color:#bbb"
                    //TODO toggle hide/view
                }
            }

        });


        blockManager.add('line-break',{
            label: `
            <span>
            <div style="font-size:2em">&para;</div><br/>
            ${t('linebreak')}
</span>
            `,
            attributes:{
                style:""
            },
            content: `<br/>`,
            category: 'Basic'
        })

        blockManager.add('structure-block', StructureBlock());
        blockManager.add('form-block', WaiverBlock(t));

        blockManager.add('children-block', ChildrenBlock(t));

        blockManager.add('accreditation', AccreditationBlock);

        blockManager.add('email', {
            category:"Forms",
            label: `<span>
<div class="fa fa-at" style="font-size:2em"></div><br/><br/>
${t("email")}
</span>`,
            content: `
<span>
<label>${t("email")}<input type="text" placeholder="E-mail" name="email" data-gjs-validation='${JSON.stringify(emailValidation)}'/></label>
<label>${t("confirmEmail")} <input type="text" placeholder="${t("confirmEmail")}" name="confirmEmail" data-gjs-validation='${JSON.stringify(confirmEmailValidation)}'/></label>
</span>
`,

        })

        blockManager.add('phone', {
            category:"Forms",
            label: `<span>
<div class="fa fa-phone" style="font-size:2em"></div><br/><br/>
${t("phone")}
</span>`,
            content: `
<span>
<label> ${t("phone")} <input type="text" placeholder="${t("phone")}" name="phone" data-gjs-validation='${JSON.stringify(phoneValidation)}' data-gjs-metakey="USER_PHONE"/></label>
</span>
`})

        blockManager.add('emergencyContact', {
            category:"Forms",
            label: `<span>
<div class="fa fa-ambulance" style="font-size:2em"></div><br/><br/>
${t("emergencyContact")}
</span>`,
            content: `
<span>
<label> ${t("emergencyContact")} <input type="text" placeholder="${t("fullName")}" name="emergencyContact" data-gjs-metakey="USER_EMERGENCY_FULL_NAME"/></label>
<label> ${t("emergencyContactRelation")} <input type="text" placeholder="${t("relationPlaceholder")}" name="emergencyRelationship" data-gjs-metakey="USER_EMERGENCY_RELATION"/></label>
<label> ${t("emergencyContactPhone")} <input type="text" placeholder="${t("phone")}" name="emergencyPhone" data-gjs-validation='${JSON.stringify(phoneValidation)}' data-gjs-metakey="USER_EMERGENCY_PHONE"/></label>
</span>
`})

        /*
        blockManager.add('mdc-input',{
            label:'MDC Input',
            content:`
<label class="mdc-text-field mdc-text-field--outlined" style="margin-top:3px">
  <span class="mdc-notched-outline mdc-notched-outline--notched">
    <span class="mdc-notched-outline__leading"></span>
    <span class="mdc-notched-outline__notch ">
      <span class="mdc-floating-label mdc-floating-label--float-above" id="my-label-id">Your Name</span>
    </span>
    <span class="mdc-notched-outline__trailing"></span>
  </span>
  <input type="text" class="mdc-text-field__input" aria-labelledby="my-label-id">
</label>
<script>
const textField = new MDCTextField(document.querySelector('.mdc-text-field'));
    mdc.ripple.MDCRipple.attachTo(document.querySelector('.mdc-text-field'))

</script>
            `
        })

*/
        //commands on the editor

        //style


        //for device


        const column1 = blockManager.get('column1');
        if(column1){
            column1.set({content:'<div data-gjs-type="default" style="height:100px;margin:8px auto;padding:8px"></div>', type:'default'})
        }

        editor.Commands.add('show-layers', {
            getRowEl(editor) {
                return editor.getContainer().closest('.editor-row');
            },
            getLayersEl(row) {
                return row.querySelector('.layers-container')
            },

            run(editor, sender) {
                const lmEl = this.getLayersEl(this.getRowEl(editor));
                lmEl.style.display = '';
            },
             stop(editor, sender) {
                const lmEl = this.getLayersEl(this.getRowEl(editor));
                lmEl.style.display = 'none';
            },
        });
        editor.Commands.add('show-styles', {
            getRowEl(editor) {
                return editor.getContainer().closest('.editor-row');
            },
            getStyleEl(row) {
                return row.querySelector('.styles-container')
            },

            run(editor, sender) {
                const smEl = this.getStyleEl(this.getRowEl(editor));
                smEl.style.display = '';
            },
            stop(editor, sender) {
                const smEl = this.getStyleEl(this.getRowEl(editor));
                smEl.style.display = 'none';
            },
        });

        editor.Commands.add('show-traits', {
            getTraitsE1(editor) {
                const row = editor.getContainer().closest('.editor-row');
                return row.querySelector('.traits-container');
            },
            run(editor, sender) {
                this.getTraitsE1(editor).style.display = '';
            },
            stop(editor, sender) {
                this.getTraitsE1(editor).style.display = 'none'
            },
        });


        editor.on('run:Input:before', opts => {
           window.alert("import")
        });

        editor.DomComponents.addType('select', {
            model: {
                defaults: {draggable: true}
            }
        });
        editor.DomComponents.addType('textarea', {
            model: {
                defaults: {draggable: true}
            }
        });
        editor.DomComponents.addType('button', {
            model: {
                defaults: {draggable: true}
            }
        });
        editor.DomComponents.addType('label', {
            model: {
                defaults: {
                    draggable: true,
                    droppable:true,
                    contenteditable:true

                }
            }
        });
        editor.DomComponents.addType('checkbox', {
            model: {
                defaults: {draggable: true}
            }
        });
        editor.DomComponents.addType('radio', {
            model: {
                defaults: {draggable: true}
            }
        });

        editor.DomComponents.addType('default',{
            model:{
                defaults:{
                    resizable:true
                }
            }
        });


        editor.DomComponents.addType('structure',{
            model:{
                defaults:{
                    resizable:{
                        "cl":1,
                        "cr":1,
                        tl:0,
                        tc:0,
                        tr:0,
                        bl:0,
                        br:0,
                        bc:0,
                        autoHeight:"true",
                        unitWidth:"%"
                    },
                }
            },
            isComponent:el=>{
                let className = el.className+" "+ (el.getAttribute? el.getAttribute("classname"):'');
                console.log(className);

                if(className && className.includes("structure")){
                    return {type:"structure"};
                }
            }
        })


        createValidationTraitType({editor, traitManager: editor.TraitManager, type:"Regex", placeholder:t("regex"), validation})
        createValidationTraitType({editor, traitManager: editor.TraitManager, type:"RegexMessage", placeholder:t('regexError'), validation})
        createValidationTraitType({editor, traitManager: editor.TraitManager, type:"MatchInput", placeholder:t("matchInput"), validation})
        createValidationTraitType({editor, traitManager: editor.TraitManager, type:"MatchMessage", placeholder:t("matchInputError"), validation})
        createValidationTraitType({editor, traitManager: editor.TraitManager, type:"MaxLength", placeholder:t("maxLength"), validation})
        createValidationTraitType({editor, traitManager: editor.TraitManager, type:"MaxLengthMessage", placeholder:t("maxLengthError"), validation})

        editor.TraitManager.addType('metaMaker',{
            createInput:({component})=>{
                //console.log(metaMaker.current);
                const attributes = component.getAttributes && component.getAttributes();
                if(attributes && attributes.name){
                    const input = document.createElement('input');
                    input.placeholder = t("medatadaKey");

                    input.defaultValue = component.attributes.metakey || attributes["data-gjs-metakey"] ||"";

                    input.onchange = (e)=>{
                        e.preventDefault()
                        e.stopPropagation()
                        component.setAttributes({"data-gjs-metakey":e.target.value})
                    }
                    return input;
                }
            }
        })

        editor.DomComponents.addType('input', {

            view: {},
            model: {

                defaults: {
                    traits: [

                        // Strings are automatically converted to text types
                        'name', // Same as: { type: 'text', name: 'name' }
                        'placeholder',
                        {
                            type: 'select',
                            label: 'Type',
                            name: 'type',
                            options: [
                                {id: 'text', name: 'Text'},
                                {id: 'email', name: 'Email'},
                                {id: 'password', name: 'Password'},
                                {id: 'number', name: 'Number'},
                            ]
                        },
                        {
                            type: 'checkbox',
                            name: 'required',
                        },
                        {label: 'Regex', type: 'Regex'},
                        {
                            label: ' ',
                            type: 'RegexMessage',
                        },
                        {
                            label: 'Match Input',
                            type: 'MatchInput',
                        },
                        {
                            label: ' ',
                            type: 'MatchMessage',
                        },
                        {
                            label: 'Max Length',
                            type: 'MaxLength',
                        },
                        {
                            label: '',
                            type: 'MaxLengthMessage',
                        },
                        {
                            label:"Metadata Key",
                            type:"metaMaker"
                        }


                    ],
                    attributes: {},
                    draggable: true,
                    droppable:true

                },
                init() {
                    this.on('change:attributes:Regex', this.handleTypeChange);
                    this.listenTo(this, 'change:testprop', this.handlePropChange)
                    if (this.getAttributes && this.attributes && this.getAttributes().name) {
                        if(this.attributes.validation && typeof this.attributes.validation !== 'string') {
                            validation.current[this.getAttributes().name] = this.attributes.validation;
                        }

                        if(this.attributes.metakey){
                            this.addAttributes({"data-gjs-metakey": this.attributes.metakey});
                        }

                    }
                },
                handlePropChange() {

                },

                handleTypeChange() {
                    console.log('updated')
                },
            }
        })
        //listener
        editor.on('run:export-template', () => console.log('After command run. '));
        editor.on('abort:export-template', () => console.log('after command aborted'));
        editor.on('change:device', () => console.log('current device:  ' + editor.getDevice()));





        editor.on('component:remove', function(model) {

            if(model && model.getName() === 'Input'){
                let name = model.getAttributes().name;
                console.log("removing", model.getAttributes().name)
                if(editor.Canvas && editor.Canvas.getDocument() && editor.Canvas.getDocument().querySelectorAll('[name="'+model.getAttributes().name+'"]').length >1){
                    return;//don't remove if there is another input with this name
                }
                if(validation.current[name]){
                    delete validation.current[name];
                }

            }

        });
    }
}





function createValidationTraitType({ traitManager, type, placeholder, validation}){

    traitManager.addType(type,{
        createInput:({component,el})=>{
            const attributes = component.getAttributes && component.getAttributes();
            if(attributes && attributes.name){
                const input = document.createElement('input');
                input.placeholder = placeholder;

                if(validation.current[attributes.name] && validation.current[attributes.name][type]){
                    input.defaultValue = validation.current[attributes.name][type]+"";
                }

                console.log(el);
                input.onchange = (e)=>{
                    e.preventDefault()
                    e.stopPropagation()
                    if(e.target.value !== undefined && e.target.value !== "") {
                        validation.current[attributes.name] = validation.current[attributes.name] || {}
                        validation.current[attributes.name][type] = e.target.value;
                    }else if(validation.current[attributes.name]){
                        delete validation.current[attributes.name][type]
                    }
                }


                return input;
            }

        }
    })
    
}

const PANELS = {
    id: 'panel-top',
    el: '.panel__top',
};


