import React, {useEffect, useRef} from 'react';


export default function useMounted(initialState = false){
    const mounted =  useRef(initialState);

    useEffect(()=>{
        mounted.current = true;
        return ()=>{
            mounted.current = false;
        }
    },[])

    return mounted;
}
