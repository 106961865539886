import React, {useState} from "react";
import '../css/formsLoader.css'
import GrapeEditorTest from "./GrapeTests";
import {TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import  {useLoggedInRequest} from "../common/components/useRequest";
import Login from "../common/components/Login";
import {fromBase64} from "../common/utils/Utils";
import {State} from "../common";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {MultipleSelect} from "../common/components/Filters";
import {useTranslation} from "react-i18next";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import useConfirmDialog, {ConfirmDialog, InputDialog} from "../common/utils/useConfirmDialog";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Typography from "@mui/material/Typography";
import {Create, List, Preview, Publish} from "@mui/icons-material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const useStyle = makeStyles(theme=>({
    fields:{
        display:"flex",
        width:"100%",
        alignItems:"center",
        justifyContent:"center"
    },
    action:{
        display:'flex',
        alignItems:'baseline',
        justifyContent:'center'
    },
    actionButtons:{
        width:"5em",
        margin:"0.5em"
    }
}))

export default function FormsBuilder({gym, id}){

    const classes = useStyle();
    //load a specific form
    const [t] = useTranslation("forms")
    const [html, setHtml] = useState("<div>loading...</div>");
    const [title, setTitle] = useState("");
    const [version,setVersion] = useState(1)
    const [description, setDescription] = useState("");
    const [actions, setActions] = useState(undefined);
    const [validation, setValidation] = useState({});
    const [metaMaker, setMetaMaker] = useState({});
    const [dialog, confirm] = useConfirmDialog()
    const [level, setLevel] = useState("NONE")
    //const[formId,setFormId]= useState("");

    const {request} = useLoggedInRequest(gym,[id])
        .call((request)=>{

            request.get("/forms/"+id).then(res=>{
                if(res.result && res.result.length){
                    res = res.result[0];
                    setTitle(res.title);
                    setDescription(res.description);
                    setVersion(res.version);
                    setHtml(fromBase64(res.document))
                    setLevel(res.level);
                    if(res.meta){
                        if(res.meta.validation){
                            setValidation(res.meta.validation);
                        }

                        if(res.meta.actions){
                            setActions(res.meta.actions);
                        }

                        if(res.meta.metaMaker){
                            setMetaMaker(res.meta.metaMaker);
                        }
                    }


                }
            })
        })



    function save(html,validation, metaMaker){
            //put
            let json = {html,title,description, level}

            json.meta = {}

            if(actions && actions.length){
                json.meta.actions = actions;
            }
            if(validation){
                json.meta.validation = validation;
            }
            if(metaMaker){
                json.meta.metaMaker = metaMaker;
            }

            request.put("/forms/"+id, {},json).then((res)=> {
                console.log(res.result);
                if(res.state === State.SUCCESS && res.result){
                    window.alert(t("Successfully saved form"));
                    setVersion(res.result.version || version);
                }else{
                    window.alert(t("Could not save form"))
                }

            })
    }






    function selection(value){
        setActions(value);
    }

    return (
        <div className={"App"}>
            {dialog}
            <Login gym={gym}/>
            <Button variant={"contained"} onClick={()=>window.location.href='/'+gym+'/loader'}>
                <KeyboardBackspaceIcon/>
                {t("backToList")}
            </Button>
            <div className={classes.fields}>
                <TextField value={title}
                           label={t("title")}
                           onChange={(e)=> {
                               setTitle(e.target.value);
                           }
                           }
                />
                <TextField value={description}
                           label={t("description")}
                           onChange={(e)=>{
                               setDescription(e.target.value)
                           }
                           }
                />
                <MultipleSelect onCommit={selection} list={{"CREATE":"Create User", "PREFILL":"Prefill Form"}} label={"Action"} initialValue={actions}/>
                <Select value={level} onChange={(e)=>setLevel(e.target.value)}>
                    <MenuItem value={"NONE"}>{t('levels.ALL')}</MenuItem>
                    <MenuItem value={"USER"}>{t('levels.USER')}</MenuItem>
                    <MenuItem value={"STAFF"}>{t('levels.STAFF')}</MenuItem>
                    <MenuItem value={"SUPERVISOR"}>{t('levels.SUPERVISOR')}</MenuItem>
                    <MenuItem value={"ADMIN"}>{t('levels.ADMIN')}</MenuItem>

                </Select>
            </div>
                <span className={classes.action}>
                    <span className={classes.actionButtons}>
                        <IconButton variant="outlined" color="default" onClick={publish(t,request,id,version,gym)}>
                            <Publish/>
                        </IconButton>
                        <br/>
                        <Typography variant={"caption"} color={"textSecondary"}>{t("Publish")}</Typography>
                    </span>
                <span className={classes.actionButtons}>
                        <IconButton variant="outlined" color="default" onClick={()=>window.open("/"+gym+"/new/"+id)}>
                            <Preview/>
                        </IconButton>
                        <br/>
                        <Typography variant={"caption"} color={"textSecondary"}>{t("viewForm")}</Typography>
                    </span>
                <span className={classes.actionButtons}>
                        <IconButton variant="outlined" color="default" onClick={()=>window.open("/"+gym+"/view/list/"+id)}>
                            <List/>
                        </IconButton>
                        <br/>
                        <Typography variant={"caption"} color={"textSecondary"}>{t("viewFilledForms")}</Typography>
                    </span>
                <span className={classes.actionButtons}>
                        <IconButton variant="outlined" color="default" onClick={copyForm(gym, confirm, t, request, id, version)}>
                            <ContentCopyIcon/>
                        </IconButton>
                        <br/>
                        <Typography variant={"caption"} color={"textSecondary"}>{t("copy")}</Typography>
                    </span>
            </span>


            <GrapeEditorTest gym={gym} html={html} formValidation={validation} metaMaker={metaMaker} save={save} />

        </div>

    )
}



export function copyForm(gym, confirm, t, request, id, version){
    return async () =>{

        const title = await confirm({
            Type:InputDialog,
            text:t("enterNewTitle")
        });


        if(title) {
            const res =  await request.post("/forms/" + id + "/" + version + "/copy", {title})
            if(res.state === State.FAILED && res.result && res.result.message === 'duplicate'){
                window.alert(t("duplicateForm"))
            }else if(res.state === State.FAILED){
                window.alert(res.message);
            }else if(res.result && res.result.formId){
                confirm({
                    Type:ConfirmDialog,
                    text:t("copiedForm"),
                    confirmText:t("yes"),
                    cancelText:t("no")
                }).then(redirect=>{
                    if(redirect){
                        window.location.href ="/"+gym+"/builder/"+res.result.formId
                    }
                })

            }else{
                window.alert(res.message);
            }
        }
    }
}


export function publish(t, request, id, version, gym){
    return async ()=>{
            const res = await request.put("/forms/"+id +"/"+version+"/publish", {},{});

            if(res.state === State.SUCCESS){
                if(window.confirm(t("openPublishedForm"))){
                    window.open("/"+gym+"/new/"+id)
                }
            }else {
                window.alert(t("The form could not be published"));
            }


    }
}

export function deleteForm(t, request, id, version){
    return async ()=>{
        const res = await request.delete("/forms/"+id+(version?"/"+version:""));

        console.log(res);
    }
}