import React, {useEffect, useState} from 'react';
import Login from "../common/components/Login";
import SnackbarAlert from "../common/components/SnackbarAlert";
import {useLoggedInRequest} from "../common/components/useRequest";
import {State} from "../common";
import {useTranslation} from "react-i18next";
import {DateTime} from "luxon"
import JsonTable, {BasicHeader, BasicRow} from "../common/components/Table";
import {Paper} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyle = makeStyles(theme=>({
    pages:{
        margin:"auto",
        textAlign:"center"
    }
}))

export default function FilledFormView({gym,formId}){

    const classes = useStyle();
    const [t] = useTranslation("forms");
    const [message, setMessage] = useState();
    const [formList, setFormList] = useState([]);
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [page, updatePage] = useState(1);
    const [pageSize, setPageSize] = useState(10);


    function setPage(page){
        if(page <1){
            updatePage(1);
        }else if(page >= numberOfPages){
            updatePage(numberOfPages)
        }else{
            updatePage(page);
        }
    }

    function getDocuments(request){
        const query = {page};
        if(formId && formId !== ""){
            query.formId = formId;
        }
        request.get("/data",query).then((res) => {
            if(res.result && res.result.documents && res.result.documents.length){
                setFormList(res.result.documents);
                setNumberOfPages(Math.ceil(res.result.count / pageSize));
                return;
            }else if (res.state ===State.FAILED){
                setMessage(t("couldNotFetchForms"))
            }
            setFormList([])
            setNumberOfPages(1);
        })
    }

    const request = useLoggedInRequest(gym)
        .call(getDocuments)
        .unauthorized((res)=>{
            if(res.result && res.result ==="wrongAccessLevel"){
                setMessage(t(res.result));
            }else{
                window.location.href = "/"+gym+"/login?redirect="+window.location.href;
            }
        })


    useEffect(()=>{
        if(request && request.request) {
            getDocuments(request.request)
        }
    },[page,request])

    function onClick(form){

        window.open("/"+gym+"/view/"+form.ID, "__blank")
    }

    return (
        <div className={"App"}>
            <Login gym={gym}/>
            <SnackbarAlert title={"Oops"} message={message}/>
            <br/>

            <JsonTable header={["date","formId", "version"]}
                       data={formList}
                       beforeHeader={t}
                       keyName={"ID"}
                       rowClicked={onClick}
                       beforeRow={{
                           date:(date)=>DateTime.fromISO(date).toFormat('yyyy-MM-dd, HH:mm')
                       }}
                       collapsedRow={(json)=>{
                           function children(json){
                               return <JsonTable data={json.child.length?json.child:[json.child]}
                                                 Header={BasicHeader}
                                                 Row={BasicRow}
                                                 keyName={""} />
                           }
                           return <JsonTable data={[json.data]}
                                             Header={BasicHeader}
                                             Row={BasicRow}
                                             keyName={""}
                                             beforeRow={{
                                                 child:(c)=>{
                                                     return c.length || 1
                                                 },
                                                 accreditation:(a)=>{
                                                     const list = []
                                                     for(let x in a){
                                                         list.push(<div key={x}>{x}: {a[x].result}</div>)
                                                     }//TODO

                                                     return <div>{list}</div>
                                                 }
                                             }}
                                             collapsedRow={(json.data && json.data.child) &&  children
                                             }
                           />
                       }}
                       component={Paper}
            />
            <div className={classes.pages}>
                <Button  onClick={()=>setPage(page-1)}> {"⟨"} </Button>
                Page: <input className={"pageNumber"} type={"number"} min={1} onChange={(e)=>setPage(Number(e.target.value))}/>
                <span> of {numberOfPages}</span>
                <Button  onClick={()=>setPage(page+1)}> {"⟩"} </Button>
            </div>
        </div>
    )

}