import React, {Fragment, useCallback, useEffect, useRef, useState} from 'react';
import {request, useQuery} from "../common";
import {fromBase64} from "../common/utils/Utils";
import {stringify} from "querystring";
import Login, {useLogin, useLogout} from "../common/components/Login";
import {State} from "../common/components/Response";
import UserSearch from "../common/components/UserSearch";
import useConfirmDialog, {AlertDialog, ConfirmDialog} from "../common/utils/useConfirmDialog";
import {useTranslation} from "react-i18next";
import {Button} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useLoggedInRequest} from "../common/components/useRequest";
import "../css/error.css"

const useStyle = makeStyles(theme=>({
    submit:{
        margin:"2em auto",
        textAlign:"center",
        display:"block"
    },
    notFound:{
        textAlign:"center",
        margin:"auto",
        "& img":{
            width:"100%",
            height:"30vw",
            minHeight:"15em",
            objectFit:"cover"
        },
        color:theme.palette.text.secondary,
        fontSize:"2.5vw"
    }

}))

export default function Form({gym, id}){


    const classes = useStyle();
    const [t] = useTranslation('forms');
    const [common] = useTranslation('common')
    const [form, setForm] = useState('<div>Loading...</div>');
    const [version, setVersion]= useState(1);
    const [level, setLevel] = useState(undefined);
    const [meta, setMeta] = useState({});
    const [validation, setValidation] = useState({});
    const [showSearch, setSearchShown] = useState(false);
    const [is404, set404] = useState(false);
    const [showLogin, loginShown] = useState(false);
    const forbidden = useRef(false);

    const ref = useRef();
    const data = useRef({});
    const userMeta = useRef({});
    const [query] =useQuery();

    const [userId,setUserId] = useState(undefined);

    const [dialog, confirm] =useConfirmDialog();

    const [currentUser, setCurrentUser] =useState({})

    useEffect(addScript("addChildren.js"),[]);


    useEffect(()=>{
        setUserId(query.userId)
        setData(query);
    },[query,form])

    useLoggedInRequest(gym)
        .call((request)=>{
            if(userId){
                request.get("/users/profile/"+userId).then(res=>{
                    if(res.result && res.result.ID){
                        setData({
                            ID: res.result.ID,
                            firstname:res.result.firstname,
                            lastname:res.result.lastname,
                            barcode:res.result.user_barcode,
                            email:res.result.user_email,
                            login:res.result.user_login,
                            accessLevel:res.result.accessLevel,
                            birthdate:res.result.birthdate
                        })
                    }
                })
            }
        })
        .unauthorized((res)=>{
            console.log("error", res)
        })



    function fetchForm(){
        forbidden.current =false;
        request.get("/"+gym+ "/forms/" + id+"/latest").then((res) => {
            if(res.result && res.result.length){
                setForm(fromBase64(res.result[0].document));
                setVersion(res.result[0].version)
                setLevel(res.result[0].level)

                let meta = res.result[0].meta;
                if(meta){
                    try{
                        if(meta.validation){
                            setValidation(meta.validation);
                        }
                        setMeta(JSON.parse(meta));
                    }catch(e){
                        setMeta(meta);
                    }
                }
                return;
            }else if(res.state === State.FORBIDDEN){
                setForm('<div style="width:60%;text-align:center;margin:auto;display:block"><img src="/Forbidden.png" alt="Forbidden" width="55%" />' +
                    `<h5>${t("formAccessForbidden")}</h5></div>`)
                loginShown(true);
                forbidden.current = true;
            }else if (!res.result || !res.result.length){
                set404(true);
                if(window.parent){
                    window.parent.postMessage({state:State.NOT_FOUND},"*")
                }
                return;
            }else{
                console.log(res)//TODO
            }
            if(window.parent){
                window.parent.postMessage(res,"*")
            }
        })
    }


    useEffect(()=>{
       fetchForm();
    },[gym, id]);


    useEffect(()=>{
        if((level && level !== 'NONE') || (meta.actions && meta.actions.includes('PREFILL'))){
            loginShown(true);
        }else{
            loginShown(false);
        }
    },[level, meta])


    const onLogin=useCallback((user)=>{
        if(forbidden.current){
            fetchForm();
        }
        if(!user || !user.ID){
            request.get("/"+gym+"/users/profile").then(res=>{
                if(res.state === State.SUCCESS && res.result && res.result.ID){
                    onLogin(res.result);
                }
            }).catch(console.log)
        }

        if(["ADMIN","SUPERVISOR","STAFF"].includes(user.accessLevel)){
            setSearchShown(true);
        }else if(user && user.ID){
            setData(user)
        }

        setCurrentUser(user);
    },[gym]);

    const onLogout = useCallback(()=>{
        setSearchShown(false);
    },[])

    const [login] = useLogin(onLogin, onLogout);
    const logout = useLogout({gym});




    function setData(data){
        const selectors={
            user_email:"mail",
            birthdate:"birth"
        }

        let el;
        let key;
        for(let x in data){
            if(x === 'ID' || x === 'id' || x=== 'userId'){
                continue;
            }
            key = selectors[x] || x;
            el = document.querySelectorAll('[name*="'+key+'" i]');
            if(el && el.length) {
                el.forEach((v)=>{
                    switch(v.type){
                        case 'radio':
                            v.checked = v.value === data[x];
                            break;
                        case 'checkbox':
                            v.checked = true;
                            break;
                        default:
                            if(v.name !== key && v.name.includes(".")){
                                return;
                            }
                            v.setAttribute('value', data[x] ||"");
                    }
                    v.setAttribute('disabled', !!data[x]?'true':'false')
                    onChange({target:v});
                })
            }
        }


        if(ref.current && (data.ID || data.id || data.userId)){
            el = document.querySelector('[name*="userId" i]');
            if(!el){
                el = document.createElement('input');
                el.disabled=true;
                el.name="userId";
                el.hidden=true;
                ref.current.appendChild(el)
            }
            el.setAttribute('value', data.ID || data.id || data.userId);
            onChange({target:el});
        }

    }


    function onChange(e){
        const {name, value} = e.target;

        clearErrorMessage();
        validateInput(e.target, value)
        //addValue(name, value);
        //console.log(data.current);
        //console.log(document.getElementById("__form").elements);

       // console.log(data.current);

    }

    function addValue(name, value, override = false){
        if(name && value !== undefined){

            const names = name.split(/[.$@#*]/)

            let out = data.current;
            let parent =undefined;
            let i = 0;
            for(; i< names.length -1; i++){
                if(!out[names[i]]){
                    out[names[i]] = {};
                }
                parent = out;
                out = out[names[i]];
            }
            if(Array.isArray(out)){
                parent = out;
                out = out[out.length -1]

            }


            if(!override && out[names[i]]){

                if(Array.isArray(parent)){
                    parent.push({[names[i]]:value})
                }else if(Array.isArray(out[names[i]])){
                    out[names[i]].push(value)
                }else if(parent){
                    parent[names[i-1]] = [out, {[names[i]]:value}]
                }else{
                    out[names[i]] = [out[names[i]], value];
                }

            }else{
                out[names[i]] = value;
            }

            //console.log(out);

            //data.current[name] = value;
        }
    }

    async function submit(e){
        e.preventDefault();
        clearErrorMessage();
        const elements =e.currentTarget.elements;
        data.current = {};
        userMeta.current = {};
        let valid = true;
        for(let x = 0; x< elements.length; x++){

            if(!(elements[x].type === 'checkbox' || elements[x].type === 'radio') || elements[x].checked){
                addValue(elements[x].name, elements[x].value);
                if(elements[x].getAttribute('data-gjs-metakey')){
                    userMeta.current[elements[x].getAttribute('data-gjs-metakey')] =  elements[x].value;
                }
            }
            if(!validateInput(elements[x],elements[x].value)){
                valid = false;
            }else{

            }
        }
        if(!valid){
            return;
        }


        data.current.__userMeta = userMeta.current;
        const res = await request.post("/"+gym+"/data/"+id+"/"+version+"?"+stringify(meta),data.current);

        if(res.state === State.SUCCESS) {


            if(res.result && res.result.userResponse && res.result.userResponse.state === "FAILED"){
                const response = await confirm({
                    Type: ConfirmDialog,
                    title:"Oops",
                    text:t("couldNotCreateAccount"),
                    cancelText: t("retry"),
                });
                if(!response){
                    return;
                }
            }

            if(window.parent){
                 window.parent.postMessage(res,"*")
            }


            switch (currentUser.accessLevel) {
                case 'CHILD':
                case 'USER':
                    await confirm({
                        Type: AlertDialog,
                        title:t('successfullySavedForm'),
                        text:t("youWillBeLoggedOut")
                    })
                    return logout();
                case 'STAFF':
                case 'SUPERVISOR':
                case 'ADMIN':
                    return (await confirm({
                        Type: ConfirmDialog,
                        title:t('successfullySavedForm'),
                        text:t('remainConnected?'),
                        cancelText:common("logout")
                    }))? window.location.reload(): logout();
                default:
                    await confirm({
                        Type: AlertDialog,
                        text:t('successfullySavedForm'),
                    })
                    window.location.reload();
            }

        }

    }

    function displayErrorMessage(input,message){
        const el = document.createElement('div');
        el.innerHTML = '<br/>'+message;
        el.className="error-message";
        input.insertAdjacentElement('afterend', el);
    }
    function clearErrorMessage(){
        let messagesElements = document.getElementsByClassName('error-message');
        let messages = Array.prototype.slice.call(messagesElements);
        for(let i= 0 ; i < messages.length; i++){
            messages[i].remove();
        }
    }
    function findInputValue(name,inputs){
        for (var i = 0, element; element = inputs[i++];) {
            if (element.name===name){
                return element.value;
            }
        }
    }


    function validateInput(input,value){
        let inputs = document.getElementById("__form").elements;
        let name = input.name;
        let valid = true;
        if(validation[name]){
            let rules = validation[name];
            for(let rule in rules){
                if(rule ==="MatchInput"){
                    if(!(value===findInputValue(rules[rule],inputs))){
                        input.className = "invalid";
                        valid = false;
                        if(rules['MatchMessage']){
                            displayErrorMessage(input,rules['MatchMessage']);
                        }else{
                            displayErrorMessage(input,t("The input ")+ name + " doesnt match " + rules[rule]);
                        }

                    }else{
                        input.className = "valid";
                    }
                }else if(rule==="MaxLength"){
                    if(value.length > rules[rule]){
                        input.className = "invalid";
                        valid = false;
                        if(rules['MaxLengthMessage']){
                            displayErrorMessage(input,rules['MaxLengthMessage']);
                        }else{
                            displayErrorMessage(input,t("The input ") + name + t(" exceed the limit of ") + rules[rule]);
                        }
                    }else{
                        input.className = "valid";
                    }
                }else if(rule==="Regex"){
                    if(!value.match(rules[rule])){
                        input.className = "invalid";
                        valid = false;
                        if(rules['RegexMessage']){
                            displayErrorMessage(input,rules['RegexMessage']);
                        }else{
                            displayErrorMessage(input,t("The input ")+ name + t(" doesn't respect the pattern ") + rules[rule]);
                        }
                    }else{
                        input.className = "valid";
                    }
                }
            }
        }

        return valid;
    }



    return (
        <div>
            {dialog}
            {showLogin &&
            <Login gym={gym} onLogin={login}/>
            }
            {(meta.actions && meta.actions.includes('PREFILL')) &&
                <Fragment>

                    {
                        showSearch &&
                            <UserSearch gym={gym} userSelected={(json)=>{
                                setData(json);
                            }}/>
                    }
                </Fragment>

            }
            <link href="https://unpkg.com/material-components-web@latest/dist/material-components-web.min.css" rel="stylesheet"/>
            <link href="/css/base.css" rel="stylesheet"/>
            <script src="https://unpkg.com/material-components-web@latest/dist/material-components-web.min.js"/>
            {is404?
                <div className={classes.notFound}>
                    <img src={'/NotFound.png'}/>
                    {common("notFound")}
                </div>
                :
                <form id={'__form'} onSubmit={submit} >{/*method={"post"} action={"/api/"+gym+"/data/"+id+"/"+version+"?"+stringify(meta)}*/}
                    <div ref={(r)=>{
                        if(ref.current){
                            ref.current.removeEventListener('change',onChange);
                        }
                        if(r){
                            ref.current = r;
                            r.addEventListener('change',onChange)
                        }
                    }} dangerouslySetInnerHTML={{__html:form}}/>

                    {!forbidden.current &&
                    <Button className={classes.submit} type={'submit'} variant={"contained"} color={"primary"}>
                        {common("submit")}
                    </Button>
                    }

                </form>
            }

        </div>
    )

}


function addScript(name){
    const el  =document.createElement('script');
    el.src = "/scripts/"+name
    el.type = 'text/javascript';

    return ()=>{
        document.body.appendChild(el);

        return ()=>el.remove();
    }

}