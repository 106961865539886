import {useTranslation} from "react-i18next";

const {useState} = require("react");

export function checker(setter, result){
    setter(result);

    return !result;
}

export function isRequired(ref,field){

    return !ref.current[field]?"This field is required":undefined
}

export function isFormatted(ref, field, regex, format){
    return (ref.current[field] && !regex.test(ref.current[field]))? format:undefined;
}

export function verify(e, check) {
    if (check[e.target.id]) {
        return check[e.target.id]();
    }
}



export function useValidation(ref, field,isRequired, format = {regex:undefined, message:""}){
  const [t] = useTranslation('common');
  const [message, setMessage] = useState(undefined);

  function verify(e){
    let message;
    if(isRequired){
      message = !ref.current[field]?t('fieldIsRequired'):undefined;
    }

    if(format && format.regex){
      message = message || isFormatted(ref,field,format.regex, t(format.message))
    }

    setMessage(message);
  }

  return [message, verify]
}
