import React, {useCallback, useEffect, useState, useRef} from "react";
import grapesjs from 'grapesjs';
import {default as webpage} from 'grapesjs-preset-webpage'
import grapesjsTabsPlugin from 'grapesjs-tabs';
import 'grapesjs-lory-slider';
import 'grapesjs/dist/css/grapes.min.css'
import '../css/grapesTest.css'
import {useTranslation} from "react-i18next";
import fr from 'grapesjs/locale/fr';
import useConfirmDialog, {InputDialog} from "../common/utils/useConfirmDialog";
import GrapesPlugin from "./grapesComponents/GrapesPlugin";
import {AccreditationModel} from "./grapesComponents/AccreditationComponent";
import {InputModal} from "./grapesComponents/InputComponent";
import EditPlugin from "./grapesComponents/EditPlugin";
import QuickStylesPlugin from "./grapesComponents/QuickStylesPlugin";

const ATTRIBUTE = ['name','placeholder','type','required','value'];
export default function GrapeEditorTest({gym, html, formValidation,save, metaMaker}) {

    const [t,i18n] = useTranslation("forms")
    const [editor, setEditor] = useState();
    const validation = useRef({})
    const [dialog, confirm] = useConfirmDialog()


    useEffect(()=>{
        const editor = grapesjs.init(INIT([GrapesPlugin(validation,t), EditPlugin, QuickStylesPlugin]));

        editor.DomComponents.addType('accreditation',AccreditationModel(gym, t, confirm))
        editor.Commands.add('save',{
            run: onSave
        })

        editor.on('block:drag:stop', (model) => {
            const modal = editor.Modal;
            if(model && model.getName){
                switch (model.getName()) {
                    case "Input":
                    case "Checkbox":
                    case "Radio":
                        inputModal(model, modal);
                        break;
                    case "Link":
                        linkModal(model);
                        break;

                }

            }
        });





        setEditor(editor)
        return ()=>editor.destroy()
    //editor.on('run:preview', ()=> console.log('After preview run. '));
    },[]);

    useEffect(()=> {
        if(editor && editor.DomComponents) {
            try {
                editor.setStyle("");
                editor.setComponents(html);

            }catch(e){
                console.log(e);
            }
        }
    },[html,editor]);

    useEffect(()=>{
        validation.current = formValidation;
    },[formValidation, metaMaker])




    const onSave = useCallback((editor)=>{
        if(save){
            save('<style>'+editor.getCss()+'</style>\n' + editor.getHtml(),validation.current);
        }
    },[save]);

    useEffect(()=>{
        if(editor){
            let command = editor.Commands.get('save');
            if(command){
                command.run = onSave
            }
        }

    },[onSave,editor])


    useEffect(()=>{
        if(editor){
            editor.I18n.setLocale(i18n.language)
        }
    }, [t, editor])


    function linkModal(model){
        confirm({
            Type:InputDialog,
            text:"Enter url: "
        }).then(url=>{
            model.setAttributes({href:url});
        })
    }

    function inputModal(model, modal){
        const component = document.createElement('div')
        const button = document.createElement('button');
        const attributes = model.getAttributes() || {};
        const rule = model.attributes.validation || {};//validation of the current input

        component.innerHTML= InputModal({name:attributes.name, validation:rule, type:model.getName()})

        let metaMakerKey;

        // let rules = {};//validation of the current input with input name as key
        component.addEventListener('change',(e)=>{
            //if attribute settings or validation settings
            if(e.target.name === 'metaMaker'){
                metaMakerKey = e.target.value;//TODO validate

            }else if(ATTRIBUTE.includes(e.target.name)){
                attributes[e.target.name] = e.target.value;
            }else{
                rule[e.target.name] = e.target.value;
            }
        });

        let valide = false;
        button.onclick=()=>{
            if(attributes.name===undefined){
                window.alert(t("Please fill the input name"));//TODO replace alerts
            }else if(validation.current[attributes.name]){
                window.alert("The name of the input is not unique");
            }else if(model.getName() === 'Radio' && (attributes.value === undefined || attributes.value ==="")){
                window.alert("Radio buttons need a value")
            }else{
                //all good we can save the attributes and validation
                valide = true;
                model.setAttributes(attributes);
                validation.current[attributes.name]=rule;

                modal.close();
            }

        }
        button.innerText = t("Save Settings");
        button.className='save-settings';
        button.style='text-align: center;color: white;';
        component.appendChild(button);
        modal.open({
            title:  '<div style="text-align: center;">' + t("Component settings") +'</div>',
            content: component,
        });

        modal.onceClose(()=>{
            //if name is undefined remove the input or already exist
            if(!valide){
                model.remove();
            }
        });
    }

    return(
        <div id={"grapesjs"}>
            {dialog}
            <div className="panel__top">
                <div className="panel__basic-actions"/>
                <div className="panel__devices"/>
                <div className="panel__switcher"/>
            </div>
            <div className="editor-row">
                <div className="editor-canvas">
                    <div id={"gjs"}/>
                </div>
            </div>
                <div className="panel__right">
                    <div className="layers-container"/>
                    <div className="styles-container"/>
                    <div className="traits-container"/>
                </div>
            <div id={"blocks"}/>
        </div>

    )
}


const INIT =(plugins) =>({
    container: '#gjs',
    i18n: {
         locale: 'fr', // default locale
        // detectLocale: true, // by default, the editor will detect the language
        // localeFallback: 'en', // default fallback
        messages: { fr },
    },
    fromElement: true,
    height: '100%',
    width: 'auto',
    commands: {
        defaults: [{
            id:'store-data',
            run(editor){
                editor.store();
            },
        }]
    },

    canvas:{
        styles:[
            "https://unpkg.com/material-components-web@latest/dist/material-components-web.min.css",
            "/css/base.css"
        ],
        scripts:[
            "https://unpkg.com/material-components-web@latest/dist/material-components-web.min.js"
        ]
    },

    //pluggin to add style
    plugins: [webpage,'grapesjs-lory-slider',grapesjsTabsPlugin,...plugins],
    pluginsOpts: {
        'gjs-preset-webpage': {
            // options
        },
        'grapesjs-lory-slider':{
            sliderBlock:{
                category: 'Extra'}
        },
       [grapesjsTabsPlugin]: {
            tabsBlock: {
                category: 'Extra'}
        },
    },
    blockManager: {
        appendTo:'#blocks',
        blocks: [
            {
                id: 'section',
                label: '<b>Section</b> ',
                attributes: {class: 'gjs-block-section'},
                content: `<section>
                                      <h1> This is a simple Title </h1>
                                      <div>This is just a lorem text : lorem ipsum</div>
                                      </section>`,

            }, {
                id: 'text',
                label: 'Text',
                content: '<div data-gjs-type="text"> Random text here </div>'
            },

            {
                id: 'image',
                label: 'Image',
                select: true,
                content: {type : 'image'},
                activate: true,
            }
        ]
    },
    panels: {
        default: [
            {
                id: 'panel-switcher',
                el: '.panel__switcher',
                buttons: [{
                    id: 'show-layers',
                    active: true,
                    label: 'Layers',
                    command: 'show-layers',
                    // Once activated disable the possibility to turn it off
                    togglable: false,
                },
                    {
                    id: 'show-style',
                    active: true,
                    label: 'Styles',
                    command: 'show-styles',
                    togglable: false,
                },
                    {
                        id: 'show-traits',
                        active: true,
                        label: 'Traits',
                        command: 'show-traits',
                        togglable: false,
                    }
                ]
            },
        ]},
    styleManager: {
        clearProperties:true,
        sectors: [{
            name: 'Dimension',
            open: false,

            buildProps: ['width', 'min-height', 'padding'],

            properties: [
                {
                    type: 'integer',
                    name: 'The width',
                    property: 'width',
                    units: ['px', '%'],
                    defaults: 'auto',
                    min: 0,
                }
            ]
        },{
            name: 'Extra',
            open: false,
            buildProps: ['background-color', 'box-shadow', 'custom-prop'],
            properties: [
                {
                    id: 'custom-prop',
                    name: 'Custom Label',
                    property: 'font-size',
                    type: 'select',
                    defaults: '32px',
                    options: [
                        { value: '12px', name: 'Tiny' },
                        { value: '18px', name: 'Medium' },
                        { value: '32px', name: 'Big' },
                    ],
                }
            ]
        },
            {
                name: 'Flex',
                open: false,
                properties: [
                    {
                        name: 'Flex Container',
                        property: 'display',
                        type: 'select',
                        defaults: 'block',
                        list: [
                            {value: 'block', name: 'Disable'},
                            {value: 'flex', name: 'Enable'}
                        ],
                    },
                    {
                        name: 'Flex Wrap',
                        property: 'flex-wrap',
                        type: 'select',
                        defaults: 'initial',
                        list: [
                            {value: 'wrap', name: 'Wrap'},
                            {value: 'nowrap', name: 'No Wrap'},
                            {value: 'wrap-reverse', name: 'Wrap Reverse'},
                            {value: 'initial', name: 'Initial'}
                        ],
                    },
                    {
                        name: 'Direction',
                        property: 'flex-direction',
                        type: 'radio',
                        defaults: 'row',
                        list: [
                            {
                                value: 'row',
                                name: 'Row',
                                className: 'icons-flex icon-dir-row',
                                title: 'Row',
                            },
                            {
                                value: 'row-reverse',
                                name: 'Row reverse',
                                className: 'icons-flex icon-dir-row-rev',
                                title: 'Row reverse',
                            },
                            {
                                value: 'column',
                                name: 'Column',
                                title: 'Column',
                                className: 'icons-flex icon-dir-col',
                            },
                            {
                                value: 'column-reverse',
                                name: 'Column reverse',
                                title: 'Column reverse',
                                className: 'icons-flex icon-dir-col-rev',
                            }
                        ],
                    },
                    {
                        name: 'Justify',
                        property: 'justify-content',
                        type: 'radio',
                        defaults: 'flex-start',
                        list: [
                            {
                                value: 'flex-start',
                                className: 'icons-flex icon-just-start',
                                title: 'Start',
                            },
                            {
                                value: 'flex-end',
                                title: 'End',
                                className: 'icons-flex icon-just-end',
                            },
                            {
                                value: 'space-between',
                                title: 'Space between',
                                className: 'icons-flex icon-just-sp-bet',
                            },
                            {
                                value: 'space-around',
                                title: 'Space around',
                                className: 'icons-flex icon-just-sp-ar',
                            },
                            {
                                value: 'center',
                                title: 'Center',
                                className: 'icons-flex icon-just-sp-cent',
                            }
                        ],
                    },
                    {
                        name: 'Align',
                        property: 'align-items',
                        type: 'radio',
                        defaults: 'center',
                        list: [
                            {
                                value: 'flex-start',
                                title: 'Start',
                                label:"start",
                                className: 'icons-flex icon-al-start',
                            },
                            {
                                value: 'flex-end',
                                title: 'End',
                                className: 'icons-flex icon-al-end',
                            },
                            {
                                value: 'stretch',
                                title: 'Stretch',
                                className: 'icons-flex icon-al-str',
                            },
                            {
                                value: 'center',
                                title: 'Center',
                                className: 'icons-flex icon-al-center',
                            }
                        ],
                    },
                    {
                        name: 'Flex Children',
                        property: 'label-parent-flex',
                        type: 'integer',
                    },
                    {
                        name: 'Order',
                        property: 'order',
                        type: 'integer',
                        defaults: 0,
                        min: 0
                    },
                    {
                        name: 'Flex',
                        property: 'flex',
                        type: 'composite',
                        properties: [
                            {
                                name: 'Grow',
                                property: 'flex-grow',
                                type: 'integer',
                                defaults: 0,
                                min: 0
                            },
                            {
                                name: 'Shrink',
                                property: 'flex-shrink',
                                type: 'integer',
                                defaults: 0,
                                min: 0
                            },
                            {
                                name: 'Basis',
                                property: 'flex-basis',
                                type: 'integer',
                                units: ['px', '%', ''],
                                unit: '',
                                defaults: 'auto',
                            }
                        ],
                    },
                    {
                        name: 'Align Self',
                        property: 'align-self',
                        type: 'radio',
                        defaults: 'auto',
                        list: [
                            {
                                value: 'auto',
                                name: 'Auto',
                            },
                            {
                                value: 'flex-start',
                                title: 'Start',
                                className: 'icons-flex icon-al-start',
                            },
                            {
                                value: 'flex-end',
                                title: 'End',
                                className: 'icons-flex icon-al-end',
                            },
                            {
                                value: 'stretch',
                                title: 'Stretch',
                                className: 'icons-flex icon-al-str',
                            },
                            {
                                value: 'center',
                                title: 'Center',
                                className: 'icons-flex icon-al-center',
                            }
                        ],
                    }
                ]
            }]
    },

});



