import {useEffect, useState} from "react";


export default function useKeyPress(targetKey, callback){

    const [keyPressed, setKeyPressed] = useState(false);


    useEffect(setListeners,[]);

    useEffect(call, [keyPressed]);


    function call(){
        if(keyPressed){
            callback();
        }
    }


    function downHandler({ key }) {
        if (key === targetKey) {
            setKeyPressed(true);
        }
    }

    function upHandler({key}){
        if (key === targetKey) {
            setKeyPressed(false);
        }
    }


    function setListeners(){
        window.addEventListener('keydown', downHandler);
        window.addEventListener('keyup', upHandler);
        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener('keydown', downHandler);
            window.removeEventListener('keyup', upHandler);
        }
    }



    return keyPressed;

}