import {ScaleLoader} from "react-spinners";
import React from "react";
import {makeStyles, useTheme} from "@material-ui/core/styles";



const useStyles = makeStyles((theme) => ({
  root:{
    width:'100%',
    textAlign:'center'
  }
}));
export function ShowLoading({loading, children,color= "#bcc2bd", height = 10, width= 1}){

  const theme = useTheme();
  const classes = useStyles();
  return(
    <span className={classes.root}>
    {!loading && children}
      {loading && <ScaleLoader loading color={theme?theme.palette.primary.light:color} height={height} width={width} widthUnit={"%"}/>}
  </span>
  )
}

