import React from 'react'
import MysqlResult from "./components/MysqlResult";
import * as Requests from "./components/Requests"
import Response,{State} from "./components/Response"
import Utils from "./utils"
import Stream from "./components/Stream"
import {ShowLoading} from "./components/Loading";
import useKeyPress from "./utils/useKeyPress";
import useBuffering from "./utils/useBuffering";
import useQuery from "./utils/useQuery";
import i18next, {LanguageSelector}  from "./locale/i18n"
import { request, paymentRequest} from "./components/Requests"
import Filters from "./components/Filters";
import TwoWaySlider from "./components/Slider"
import moment from "moment";
import {checker, isRequired, useValidation, verify} from "./utils/Verify"
import Item from "./utils/Item"
import JsonDisplay from "./components/JsonDisplay";
import ShowMore from "./utils/ShowMore";
import TabComponent from "./components/Tabs";
import useToggles from "./utils/useToggles";



moment.locale('fr', {
  months : 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
  monthsShort : 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
  monthsParseExact : true,
  weekdays : 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
  weekdaysShort : 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
  weekdaysMin : 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
  weekdaysParseExact : true,
  calendar : {
    sameDay : '[Aujourd’hui à] LT',
    nextDay : '[Demain à] LT',
    nextWeek : 'dddd [à] LT',
    lastDay : '[Hier à] LT',
    lastWeek : 'dddd [dernier à] LT',
    sameElse : 'L'
  },
  relativeTime : {
    future : 'dans %s',
    past : 'il y a %s',
    s : 'quelques secondes',
    m : 'une minute',
    mm : '%d minutes',
    h : 'une heure',
    hh : '%d heures',
    d : 'un jour',
    dd : '%d jours',
    M : 'un mois',
    MM : '%d mois',
    y : 'un an',
    yy : '%d ans'
  },
  dayOfMonthOrdinalParse : /\d{1,2}(er|e)/,
  ordinal : function (number) {
    return number + (number === 1 ? 'er' : 'e');
  }
});

export {
  Requests,
  Response,
  Stream,
  Utils,
  useBuffering,
  useQuery,
  useKeyPress,
  MysqlResult,
  ShowLoading,
  LanguageSelector,
  request,
  paymentRequest,
  i18next,
  State,
  Filters,
  TwoWaySlider,
  checker, isRequired, useValidation, verify,
  Item,
  JsonDisplay,
  ShowMore,
  TabComponent,
  useToggles
};
