import QueryString from "querystring";
import axios from 'axios';
import Response from "./Response"
import Stream from "./Stream"

const CancelToken = axios.CancelToken;

export class Request{

    constructor(url, name, token, unauthorized){
        this.url = url;
        this.name = name;
        this.header = {Authorization: ""+token};
        this.cancel = null;
        this.config= {
            headers: this.header,
            validateStatus: function(status){
                return status >= 200 && status < 500;
            },
            cancelToken: new CancelToken(this.setCanceller.bind(this))
        };
        this.register();
        this.onUnauhtorized = unauthorized;
    }

    setAuthorization(token){
        this.header.Authorization = token;
    }

    getAuthorization(){
        return this.header.Authorization;
    }

    setUnauthorized(unauthorized){
        this.onUnauthorized = unauthorized;
    }

    register(){
    }

    deregister(){
    }

    setCanceller(c){
        this.cancel = c;
    }

    cancelOnRedirect(){
        if(this.cancel){
            this.cancel();
            this.config.cancelToken =  new CancelToken(this.setCanceller.bind(this));
        }
    }

    async get(path, query, autoRedirect){

        try {
            const append = query? "?"+QueryString.stringify(query): "";
            let res = await axios.get(this.url + path + append, this.config);

            if(res.status === 401){
                return this.unauthorized(autoRedirect,res.data);
            }else if(res.status === 403){
              return new Response({state:"FORBIDDEN", result:null,message: res.data.message})
            }else if(res.status === 404){
                return new Response({state:"NOT_FOUND", result:null, message:path +" not found"})
            }
            return new Response(res.data);
        }catch(e){
            if(e.__CANCEL__){
                return new Response({state: "CANCELLED", result:null, message:"Cancelled"})
            }else {
                console.log(e);
                Stream.emit("request.error", e);
                return new Response({state: "FAILED", result: e.response, message: e});
            }
        }
    }

    async post(path, body, autoRedirect){

        try{
            let res = await axios.post(this.url+path, body, this.config);

            if(res.status === 401){
                return this.unauthorized(autoRedirect,res.data);
            }else if(res.status >= 400){
                return new Response({state:"FAILED", result: res.data, message: "Bad Request"});
            }

            return new Response(res.data);
        }catch(e){
            console.log(e);
            Stream.emit("request.error", e);
            return new Response({state:"FAILED", result: e.response, message: e});
        }
    }

    async put(path, query, body, autoRedirect){
        try{
            const append = query? "?"+QueryString.stringify(query): "";
            let res = await axios.put(this.url+path+append, body, this.config);

            if(res.status === 401){
                return this.unauthorized(autoRedirect,res.data);
            }

            return new Response(res.data);
        }catch(e){
            console.log(e);
            Stream.emit("request.error", e);
            return new Response({state:"FAILED", result: e.response, message: e});
        }
    }

    async delete(path, autoRedirect, body,query){
        try{
            let deleteConfig = {...this.config};
            if(body){
                deleteConfig.data = body;
            }
            const append = query? "?"+QueryString.stringify(query): "";
            let res = await axios.delete(this.url+path + append, deleteConfig);

            if(res.status === 401){
                return this.unauthorized(autoRedirect,res.data);
            }

            return new Response(res.data);
        }catch(e){
            console.log(e);
            Stream.emit("request.error", e);
            return new Response({state:"FAILED", result: e.response, message: e});
        }
    }

    unauthorized(autoRedirect, result){
        if(autoRedirect) {
            Stream.emit("redirect", "/login");
        }
        Stream.emit("unauthorized");
        if(this.onUnauhtorized){
            this.onUnauhtorized(result);
        }
        return new Response({state:"UNAUTHORIZED",result})
    }
}

export const request = new Request("/api","Default");
export const paymentRequest = new Request("/payment","Default");

